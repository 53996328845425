import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css"; // Import CSS file containing styles for the component
import axios from "axios";
import Env from "../Environment/Env";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

// import logo from "./logo.png";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, Card, CardContent } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { FaBars } from 'react-icons/fa';
const Main = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const inputRef = useRef(null);
  const popperRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Check user's login status and role after component mount
    checkUserLogin();
  }, []);

  const checkUserLogin = () => {
    // Simulated logic to check user's login status and role
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.role === "seller" && user.id) {
      setIsLoggedIn(true);
      setUserRole(user.role);
    }
  };

  const handleLogout = () => {
    // Handle logout logic here
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    setUserRole("");
    navigate("/login"); // Redirect to login page after logout
  };

  const handleTrackOrderClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalStatusShow(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // GetAllSubSubCatagory();
    GetAllTypes();
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };

  const GetAllTypes = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        let resp = res.data.allType;
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", resp)
        const typesArray = resp.map((item) => item.type);

        console.log("typesArray====", typesArray);
        setUniqueTypes(typesArray);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };



  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [NewArrivalOne, setNewArrivalOne] = useState([]);
  const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
  const [NewArrivalThree, setNewArrivalThree] = useState([]);
  const [NewArrivalFour, setNewArrivalFour] = useState([]);
  const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);
  const [currentActive, setCurrentActive] = useState(-1);

  const [sublistContent, setSublistContent] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [NavbarData, setNavbarData] = useState([]);

  useEffect(() => {
    getNewNavbarData();
    getAllNavbarData();
    getAllData();
  }, []);

  const getAllNavbarData = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        console.log("allTypesData===", res.data.allType);
        setNavbarData(res.data.allType);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const getAllData = () => {
    axios
      .post(`${Env.server}/api/retailnewarival/getAll`)
      .then((res) => {
        console.log("Abresc===", res.data.data[0]);
        setNewArrivalOne(res.data.data[0].One[0]);
        setNewArrivalTwo(res.data.data[0].Two[0]);
        setNewArrivalThree(res.data.data[0].Three[0]);
        setNewArrivalFour(res.data.data[0].Four[0]);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  // const options = ['Option 1', 'Option 2', 'Option 3'];
  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        console.log("Abc=====", res.data.subsubcatagories);
        // setSearchedData(res.data.subsubcatagories);
        console.log("Abc=====", res.data.subsubcatagories);

        setGetAllModelsLanding(res.data.subsubcatagories);
        setAccessoriesData(res.data.subsubcatagories);
        // subcategory-Model
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
        setOptions1(uniqueOptionsArray1);

        // category-brand

        const SubCatagoryArray1 = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
        const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
        setOptions(uniqueOptionsArray11);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  }, []);

  const uniqueSubcategories = new Set(
    GetAllModelsLanding.map((card) => card.subcatagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];

  const uniqueSubcategoriesBrand = new Set(
    GetAllModelsLanding.map((card) => card.catagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

  // Function to handle hover over a category

  const [newNav, setNewNav] = useState();
  const [newList, setNewList] = useState();
  const getNewNavbarData = () => {
    axios.get(`${Env.server}/api/filters/getNavbarDataForRetail`)
      .then((res) => {
        console.log("gettingNewnavbarData", res.data.navData)
        setNewNav(res.data.navData);
      })
      .catch((err) => {
        console.log("there is an error in fetching newnavbar data", err)
      })
  }

  const handleMouseEnter = (option) => {
    console.log('newNav', newNav)
    let TypeData = newNav && newNav.filter(
      (item) => item.type === option
    );
    console.log('option under observ ==>>>', TypeData);
    console.log('type option ==>>>', option);
    setNewList(TypeData[0].categories);

    console.log(TypeData[0].categories)


    // let categories = {}; 

    // // Populate categories object
    // TypeData.forEach((item) => {
    //   if (!categories[item.catagory]) {
    //     categories[item.catagory] = new Set([item.subcatagory]); // Using Set to ensure uniqueness
    //   } else {
    //     categories[item.catagory].add(item.subcatagory); // Adding to existing Set
    //   }
    // });

    // Render sublist content dynamically
    let sublistContent = TypeData[0].categories && TypeData[0].categories.map(({ category, subcategories }) => {
      if (category !== "undefined") {
        return (
          <Col key={category} md={4} sm={4} lg={4} xl={4} xs={4} style={{ textAlign: "initial", marginTop: '8px' }}>
            <h5
              style={{ fontWeight: "bold", fontSize: "13px", marginBottom: '0px', cursor: "pointer" }}
              className="sublist-headings"
              onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`);
              }}>
              {category}
            </h5>
            {subcategories && subcategories.slice(0, 5).map((subcategory, index) => (
              <li
                key={index}
                style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }}
                className="sublist-sub-headings hover-sublist"
                onClick={() => {
                  setIsHovered(false);
                  navigate(`/AccessoryType/${subcategory}/${option}`);
                }}>
                {subcategory}
              </li>
            ))}
            {
              [...subcategories].length > 5 && <li style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }} className="sublist-sub-headings" onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`)
              }}>See more</li>
            }
          </Col>
        )
      } else {
        return <></>
      }
    });
    // Render "See More" link
    // sublistContent.push(
    //   <div key="SeeMore" style={{display: 'flex', justifyContent: 'flex-end'}}>
    //     <h6 style={{ textDecoration: 'underline'}} className="sublist-headings">See More</h6>
    //   </div>
    // );

    // Set the rendered sublist content
    setSublistContent(
      <Container>
        <Row
          className="sublist-container"
          onMouseLeave={() => setCurrentActive(-1)}
        >
          {sublistContent}
        </Row>
      </Container>
    );

    setIsHovered(true); // User is hovering over a list item or sublist
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsHovered(false); // User is not hovering over a list item or sublist
  };

  const [ModalStatusShow, setModalStatusShow] = useState(false);
  const [SelectedOrderStatus, setSelectedOrderStatus] = useState({});

  const [OrderNumber, setOrderNumber] = useState("");
  const handleTrackOrder = () => {
    console.log("OrderNumber====", OrderNumber);
    axios
      .get(`${Env.server}/api/order/trackOrderStatus/${OrderNumber}`)
      .then((res) => {
        console.log("res====", res.data.orders);
        setSelectedOrderStatus(res.data.orders);
        setModalStatusShow(true);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const [isSideBar, setIsSideBar] = useState(false)
  const [expandedItems, setExpandedItems] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const toggleItem = (index) => {
    setExpandedItems(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
  const toggleSubMenu = (index) => {
    setSubMenu(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    })
  }

  return (
    <>
      <div
        className="pt-2 topnavlinks"
        style={{ color: "#fff", backgroundColor: "#000" }}
      >
        <div
          className="d-flex justify-content-center responsive-text-container"
          style={{ gap: "15px" }}
        >
          <Link to="/Aboutus">
            <p
              className="responsive-text"
              style={{ fontSize: "13px", margin: "0px" }}
            >
              About
            </p>
          </Link>
          <Link to="/Contactus">
            <p
              className="responsive-text"
              style={{ fontSize: "13px", margin: "0px" }}
            >
              Contact Us
            </p>
          </Link>
          <p
            onClick={handleTrackOrderClick}
            className="responsive-text"
            style={{ fontSize: "13px", cursor: "pointer", margin: "0px" }}
          >
            Track My Order
          </p>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton onClick={() => setShowModal(false)}>
              <Modal.Title>Track Your Order</Modal.Title>
            </Modal.Header>
            {ModalStatusShow ? (
              <>
                <Modal.Body>
                  {/* Add the contents of your modal here */}
                  {SelectedOrderStatus?.orderNumber ? (
                    <>
                      <h5 style={{ color: "green", fontWeight: "bold" }}>
                        Status: {SelectedOrderStatus?.readyStatus}
                      </h5>
                      <h5>Order Number: {SelectedOrderStatus?.orderNumber}</h5>
                      <h5>
                        Payment Status: {SelectedOrderStatus?.paymentStatus}
                      </h5>
                      <h5>Payment Via: {SelectedOrderStatus?.paymentType}</h5>
                      <h5>Postal Code: {SelectedOrderStatus?.postalCode}</h5>
                      <h5>Order Date: {SelectedOrderStatus?.orderPlaceDate}</h5>
                      <h5>
                        Customer Name: {SelectedOrderStatus?.customerName}
                      </h5>
                      <h5>
                        Customer Email: {SelectedOrderStatus?.customerEmail}
                      </h5>
                      <h5>Address: {SelectedOrderStatus?.customerAddress}</h5>
                      <h5>
                        Total Items: {SelectedOrderStatus?.productInfo.length}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h4 style={{ color: "red", fontWeight: "bold" }}>
                        No Order Found
                      </h4>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <>
                <Modal.Body>
                  {/* Add the contents of your modal here */}
                  <p>This is where you can track your order...</p>
                  <input
                    type="text"
                    placeholder="#123456789"
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    style={{ border: "1px solid transparent" }}
                    onClick={handleTrackOrder}
                  >
                    Track
                  </Button>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>

          {/* <p style={{ fontSize: "14px" }}>My Account</p> */}

          {localStorage.getItem("role") === "admin" ? (
            <>
              <p
                onClick={() =>
                (window.location.href =
                  `${Env.dashboard}`)
                }
                className="text-center text-black responsive-text"
                style={{
                  fontSize: "13px",
                  // backgroundColor: "#DB241B",
                  // backgroundColor: '#F8F9FA',
                  // border: '1px solid #DEE2E6',
                  // maxWidth: "90px",
                  // height: "30px", // "Height" should be changed to "height"
                  // border: "none",
                  // padding: "5px",
                  // borderRadius: "5px",
                  margin: "0px",
                }}
              >
                {localStorage.getItem("id") ? (
                  <>My Account</>
                ) : (
                  <>Trade Signup</>
                )}
              </p>
            </>
          ) : (
            <>
              <Link to={"/TradeSignup"}>
                <p
                  className="text-center text-white responsive-text"
                  style={{
                    fontSize: "13px",
                    // backgroundColor: "#DB241B",
                    // backgroundColor: '#F8F9FA',
                    // border: '1px solid #DEE2E6',

                    // maxWidth: "90px",
                    // Height: "30px",
                    // border: "none",
                    // padding: "5px",
                    // borderRadius: "5px",
                  }}
                >
                  {localStorage.getItem("id") ? <>My Account</> : <>Trade</>}
                </p>
              </Link>
            </>
          )}
        </div>
      </div>

      <Navbar
        expand="lg"
        className=" pt-2 navbar-padding d-flex jsustify-content-between"
        style={{
          borderBottom: "1px solid lightgray",
          backgroundColor: '#F7DB03',
        }}
      >
        <Container fluid className="pr-0 pl-0 ">

          <Navbar bg="transparent" expand="lg" className="custom-navbar" style={{ width: '100%' }}>
            <Navbar.Brand href="/" style={{ display: 'flex', alignItems: 'center', width: '100%', flex: '1' }}>
              <div>
                <img
                  src={Env.picLogo}
                  className="d-inline-block align-top"
                  alt="logo"
                  style={{
                    width: '150px',
                    height: '50px',
                    ...(window.innerWidth <= 768 ? { width: '140px' } : {}),
                    ...(window.innerWidth <= 576 ? { width: '120px' } : {}),
                    ...(window.innerWidth <= 400 ? { width: '100px' } : {}),
                  }}
                />
              </div>
              <div
                className="d-md-none"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto', // Pushes the search bar to the rightmost edge
                  marginTop: '8px', // Adds margin to push the search bar down
                }}
              >
                <input
                  type="text"
                  style={{
                    border: '1px solid rgba(85, 85, 85, 1)',
                    fontStyle: 'italic',
                    fontSize: '14px',
                    borderRadius: '4px',
                    width: '100%',
                    
                  }}
                  placeholder="By device, brand or item.."
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                  ref={inputRef}
                />
              </div>
            </Navbar.Brand>
          </Navbar>
          {/* sidebar for small screens */}
          <div className={`mobileSidebar ${isSideBar ? 'show' : ''}`}>
            <div className="" style={{ display: 'flex', justifyContent: 'end', padding: '20px' }}>
              <p style={{ fontSize: '25px', color: 'white' }}
                onClick={() => setIsSideBar(false)}
              >X</p>
            </div>
            <div className="sidebar-content">
              {newNav && newNav.map((item, index) => (
                <div key={index} className={`item ${expandedItems[index] ? 'expanded' : 'collapsed'}`}>
                  <div className="header" >
                    <h1
                      onClick={() => {
                        setIsSideBar(false)
                        navigate(`/AmPage3/${item.type}`)
                      }}
                    >{item.type}</h1>
                    <p style={{ fontSize: '30px' }} onClick={() => toggleItem(index)}
                    >{expandedItems[index] ? '-' : '+'}
                    </p>
                  </div>
                  <div className={`sub-content ${expandedItems[index] ? 'show' : 'hide'}`}>
                    {item.categories.length > 0 && item.categories.map((data, subIndex) => (
                      <div key={subIndex} className="category">
                        <div className="category-header" onClick={() => toggleSubMenu(subIndex)}>
                          <p
                            onClick={() => {
                              setIsSideBar(false)
                              navigate(`/AmPage4/${item.type}/${data.category}`)
                            }}
                          >{data.category}</p>
                          <p style={{ fontSize: '23px' }}>{subMenu[subIndex] ? '-' : '+'}</p>
                        </div>
                        <div className={`sub-sub-content ${subMenu[subIndex] ? 'show' : 'hide'}`}>
                          {data.subcategories.length > 0 && data.subcategories.map((category, subSubIndex) => (
                            <p key={subSubIndex}
                              onClick={() => {
                                setIsSideBar(false)
                                navigate(`/AccessoryType/${category}/${item.type}`)
                              }}
                            >{category}</p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

          </div>
          {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
          <Navbar.Collapse id="navbarScroll">
            <Form className="m-auto d-flex" style={{}}>
              <div className="">
                <div
                  className="input-group search-bar-lg"
                  style={{ margin: "0px" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="By device, brand or item.."
                    value={searchText}
                    onChange={handleInputChange}
                    onClick={handleClick}
                    style={{
                      border: "1px solid rgba(85, 85, 85, 1)",
                      fontStyle: "italic",
                      fontSize: "14px",
                    }}
                    ref={inputRef}
                  />
                  <div className="input-group-append">
                    <span
                      style={{
                        border: "1px solid rgba(85, 85, 85, 1)",
                        fontSize: "14px",
                      }}
                      className="input-group-text"
                    >
                      <i className="bi bi-search"></i>
                    </span>
                  </div>
                </div>
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  style={{}}
                  ref={popperRef}
                >
                  <Paper
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      width: "auto",
                    }}
                  >
                    <List>
                      {/* Assuming options2, options1, and options are defined */}
                      <ListItemButton disabled>
                        <ListItemText primary="Accessories" />
                      </ListItemButton>
                      {options2
                        .filter((option) =>
                          option
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/AwmPageB/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton
                              onClick={() => setSearchText(option)}
                            >
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                      <ListItemButton disabled>
                        <ListItemText primary="Models" />
                      </ListItemButton>
                      {options1
                        .filter((option) =>
                          option
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/Model/brand/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton
                              onClick={() => setSearchText(option)}
                            >
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                      <ListItemButton disabled>
                        <ListItemText primary="Brand" />
                      </ListItemButton>
                      {options
                        .filter((option) =>
                          option
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/BrandPage/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton
                              onClick={() => setSearchText(option)}
                            >
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                    </List>
                  </Paper>
                </Popper>
              </div>
            </Form>
            <div className="cart-item-icon ml-3">
              <Link to={"/Cart1"} className="nav-link">
                <i className="bi bi-cart" style={{ fontSize: "25px" }}></i>
                <span className="position-absolute top-40 translate-middle badge rounded-pill" style={{ background: "#000" }}>
                  {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                    JSON.parse(localStorage.getItem("selectedCartItems"))

                      .length) ||
                    0}
                  <span className="visually-hidden">items in cart</span>
                </span>
              </Link>
            </div>
            {!localStorage.getItem("role") ? (
              <>
                <Link to="/Singin">
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      padding: "5px 13px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      textDecoration: "underline",
                    }}
                  >

                    <h6
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      Login
                    </h6>
                  </div>
                </Link>
                <Link to="/Singup">
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      padding: "5px 13px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      textDecoration: "underline",
                    }}
                  >
                    <h6
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        textAlign: "center",
                        textWrap: "nowrap",
                      }}
                    >
                      Sign Up
                    </h6>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <Link to="/UserDashboad">
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      border: "1px solid rgba(85, 85, 85, 0.5)",
                      padding: "5px 13px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      backgroundColor: "white",
                    }}
                  >
                    <h6
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        textAlign: "center",
                        textWrap: "nowrap",
                      }}
                    >
                      Dashboard
                    </h6>
                  </div>
                </Link>
              </>
            )}

            {/* {localStorage.getItem("role") === "admin" ? (
  <>
    <button
      onClick={() =>
        (window.location.href =
          "https://backoffice.mobilebitz.co.uk/")
      }
      className="text-center text-black"
      style={{
        
        background: 'none',
        
        border: 'none',
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      {localStorage.getItem("id") ? (
        <span style={{ marginLeft: '10px', fontSize: '25px' }}>
          <i className="fa fa-user"></i>
        </span>
      ) : (
        <img
          src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
          alt="Login/Signup"
          style={{ width: '25px', height: '25px', marginLeft: '10px' }}
        />
      )}
    </button>
  </>
) : (
  
  <>
    <Link to={"/UserDashboad"}>
      <button
        className="text-center text-black"
        style={{
          
          background: 'none',
         
          border: 'none',

          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {localStorage.getItem("id") ? (
          <span style={{ marginLeft: '10px', fontSize: '25px' }}>
            <i className="fa fa-user"></i>
          </span>
        ) : (
          <img
            src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
            alt="Login/Signup"
            style={{ width: '25px', height: '25px', marginLeft: '10px' }}
          />
        )}
      </button>
    </Link>
  </>
)} */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="app d-block d-md-none">
        <div
          className=""
          style={{
            backgroundColor: 'black',
            alignItems: 'center',
            display:'flex',
            justifyContent: 'space-between',
            padding: '10px 20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Nav className="">
            <div
              style={{
                cursor: 'pointer',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
              className="d-block d-md-none"
              onClick={() => {
                setIsSideBar((prev) => !prev);
              }}
            >
              <FaBars size={30} color="white" />
            </div>
          </Nav>

          <div style={{ display: 'flex', alignItems: 'center' ,gap:'5px' }}>
            {!localStorage.getItem("role") ? (
              <>
                <Link to="/Singin" style={{ textDecoration: 'none' }}>
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      textDecoration: "none",
                      color: "white",
                      padding: "10px 20px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0056b3"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#007bff"}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        margin: 0,
                      }}
                    >
                      Login
                    </h6>
                  </div>
                </Link>
                <Link to="/Singup" style={{ textDecoration: 'none' }}>
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      padding: "10px 20px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      textDecoration: "none",
                      color: "white",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#218838"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#28a745"}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        margin: 0,
                      }}
                    >
                      Sign Up
                    </h6>
                  </div>
                </Link>
                <div className="cart-item-icon ml-3">
                  <Link to={"/Cart1"} className="nav-link">
                    <i className="bi bi-cart" style={{ fontSize: "25px", color: 'white' }}></i>
                    <span className="position-absolute top-40 translate-middle badge rounded-pill" style={{ background: "#000" }} >
                      {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                        JSON.parse(localStorage.getItem("selectedCartItems"))
                          .length) ||
                        0}
                      <span className="visually-hidden">items in cart</span>
                    </span>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <Link to="/UserDashboard" style={{ textDecoration: 'none' }}>
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      border: "1px solid rgba(85, 85, 85, 0.5)",
                      borderRadius: "8px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#f8f9fa";
                      e.currentTarget.style.color = "#007bff";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "white";
                      e.currentTarget.style.color = "black";
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        padding: '5px',
                        textAlign: "center",
                        margin: 0,
                        color: "white",
                      }}
                    >
                      Dashboard
                    </h6>
                  </div>
                </Link>
                <div className="cart-item-icon ml-3">
                  <Link to={"/Cart1"} className="nav-link">
                    <i className="bi bi-cart" style={{ fontSize: "25px", color: 'white' }}></i>
                    <span className="position-absolute top-40 translate-middle badge rounded-pill" style={{ background: "#000" }} >
                      {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                        JSON.parse(localStorage.getItem("selectedCartItems"))
                          .length) ||
                        0}
                      <span className="visually-hidden">items in cart</span>
                    </span>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="app d-none d-md-block">
        <header>
          <div className="">
            <div
              className="hamburger-icon"
              onClick={toggleSidebar}
              style={{ width: "5%" }}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </header>
        {showSidebar && (
          <div className="sidebar">
            <div className="row bannerafternavbar" style={{ padding: "0px" }}>
              <div className="col-12 text-center pt-2 pr-0">
                {/* <ul className="list-group">
              {NavbarData?.slice(0, 13).map((item, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  style={{
                    borderBottom: "1px solid lightgray",
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    fontSize: '13px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    cursor: "pointer"
                  }}
                  onMouseEnter={() => handleMouseEnter(`${item.type}`)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => navigate(`/AmPage3/${item.type}`)}
                >
                  {item.type}
                  <span
                    className="badge"
                    style={{ color: "#c5c5c5", fontSize: "13px", paddingTop: '4px', paddingBottom: '4px' }}
                  >
                    &#62;
                  </span>
                </li>
              ))}
            </ul> */}
                <ul className="list-group">
                  {NavbarData?.slice(0, 13).map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        borderBottom: "1px solid lightgray",
                        backgroundColor: `${currentActive === index ? "#FBE9E8" : "#ffffff"
                          }`,
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        fontSize: "13px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => {
                        setCurrentActive(index);
                        handleMouseEnter(`${item.type}`);
                      }}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        setShowSidebar(false);
                        navigate(`/AmPage3/${item.type}`);
                      }}
                    >
                      {item.type}
                      <span
                        className="badge"
                        style={{
                          color: "#c5c5c5",
                          fontSize: "13px",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                        }}
                      >
                        &#62;
                      </span>
                    </li>
                  ))}
                </ul>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "10px",
                  }}
                >
                  <Link to="/AllTypes">
                    <p
                      style={{
                        cursor: "pointer",
                        fontSize: "13px",
                        color: "black",
                        textDecoration: "underline",
                      }}
                    >
                      See All
                    </p>
                  </Link>
                </div>

                {/* Sublist */}
                {/* <div
              className="sublist"
              style={{
                display: isHovered ? "block" : "none",
                position: "absolute",
                top: "0",
                left: "100%",
                backgroundColor: "white",
                border: "1px solid #ccc",
                padding: "5px",
                maxHeight: "390px",
                overflowY: "auto",
                zIndex: 999,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {sublistContent}
            </div> */}
                <div
                  className="sublist"
                  style={{
                    display: isHovered ? "block" : "none",
                    position: "absolute",
                    left: "100%",
                    top: 0,
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "780px",
                    height: "390px",
                    zIndex: 999,
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {sublistContent}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Main content */}
      </div>
    </>
  );
};

export default Main;
