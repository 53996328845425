import React, { useEffect, useState, useRef } from "react";
import "./LandingPage.css";
import star from "./star.png";
import Navbar from "../Navbar/Navbar";
import Navbar1 from "../Navbar/Navbar1";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";
import { FaEnvelope } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons
import "./../Navbar/Navbar.css";
import ModelSlider from "./ModelSlider";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, CardContent } from "@mui/material";
import { BsStarFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
  IconButton,
  ListItem,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CardBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CategorySlider from "./CategorySlider";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS
import MainModel from "./MainModel";
import LimitedTimeOffers from "./LimitedTimeOffers";
import HotSale from "./HotSale";

const ltoData = [
  {
    title: "Samsung Galaxy S21",
    oldPrice: 799,
    newPrice: 699,
    endDate: "2024-06-30T23:59:59",
    image: 'https://ik.imagekit.io/cy8phtesy/Samsung_S8_Unlocked_64GB_4wMubRwYg.png?updatedAt=1715692020066',
  },
  {
    title: "Apple iPhone 12",
    oldPrice: 999,
    newPrice: 899,
    endDate: "2024-07-15T23:59:59",
    image: "https://ik.imagekit.io/cy8phtesy/ZTE_Blade_V50_Violet_Oa6K-f9X3.png?updatedAt=1715692159428https://example.com/iphone_12.png",
  },
];


const LandingPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState(["Choose Options"]);
  const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [CardData, setcardData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);


  const inputRef = useRef(null);
  const popperRef = useRef(null);

  // New Arrivals\

  //  New Ariva Work==============

  const [ContentName, setContentName] = useState("");
  const [imagesFront, setImagesFront] = useState([]);
  const [image, setImages] = useState("");
  const [DataNumber, setDataNumber] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [UniqueTypes, setUniqueTypes] = useState([]);
  const [responseImages, setResponseImages] = useState([]);
  const [NewArrivalOne, setNewArrivalOne] = useState([]);
  const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
  const [NewArrivalThree, setNewArrivalThree] = useState([]);
  const [NewArrivalFour, setNewArrivalFour] = useState([]);
  const [NavbarData, setNavbarData] = useState([]);
  const [emailSubscribe, setEmailSubscribe] = useState("");
  const [mainModel, setMainModel] = useState(null);
  const [modelsData, setModelsData] = useState(null);
  const [LTOData, setLTOData] = useState(null);



  const getDynamicLTOs = async () => {
    axios.get(`${Env.server}/api/ltos/getAll`)
      .then((res) => {
        console.log('bbbbbbbbbbb', res.data);
        setLTOData(res.data);
      })
      .catch(err => console.log('bbbbbbbbb', err));
  }
  const getDynamicModels = async () => {
    axios.get(`${Env.server}/api/models/getAll`)
      .then((res) => {
        const mainMod = res.data.filter(c => c.isMain === true)[0];
        console.log('Main Model was found ===>>>', mainMod);
        setMainModel(mainMod);
        const subcards = res.data.filter(c => c.isMain === false);
        setModelsData(subcards);
      })
      .catch(err => console.log('bbbbbbbbb', err));
  }
  const handleSubscribe = () => {
    console.log(emailSubscribe);
    if (emailSubscribe.trim() === "" || emailSubscribe.indexOf('@') === -1) {
      toast.error('Please Enter an Email', {
        position: 'top-center'
      })
      return;
    }
    axios.post(`${Env.server}/api/subscribe/create`, { email: emailSubscribe })
      .then(() => {
        toast.success('You are subscribed to Email Successfully', {
          position: 'top-center'
        })
      }).catch((err) => {
        toast.error(err.response.data.message, {
          position: 'top-center'
        });
      })
    setEmailSubscribe("");
  }

  useEffect(() => {
    getNewNavbarData()
    getAllNavbarData();
    getAllData();
    getDynamicModels();
    getDynamicLTOs();
    getNewAccessories();

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [])


  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };

  // getting new navbarData
  const [newNav, setNewNav] = useState();
  const [newList, setNewList] = useState();
  const getNewNavbarData = () => {
    axios.get(`${Env.server}/api/filters/getNavbarDataForRetail`)
      .then((res) => {
        console.log("gettingNewnavbarData", res.data.navData)
        setNewNav(res.data.navData);
      })
      .catch((err) => {
        console.log("there is an error in fetching newnavbar data")
      })
  }


  // we are getting main heading with this 

  const getAllNavbarData = () => {
    axios.get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        console.log("gettingnavbarData", res.data.allType)
        setNavbarData(res.data.allType)
      })
      .catch((err) => {
        console.log("err====", err)
      })
  }

  const getAllData = () => {
    axios.post(`${Env.server}/api/retailnewarival/getAll`)
      .then((res) => {
        console.log("Abresc===", res.data.data[0])
        setNewArrivalOne(res.data.data[0].One[0])
        setNewArrivalTwo(res.data.data[0].Two[0])
        setNewArrivalThree(res.data.data[0].Three[0])
        setNewArrivalFour(res.data.data[0].Four[0])
      })
      .catch((err) => {
        console.log("err====", err)
      })
  }

  //New Arrivals



  // const options = ['Option 1', 'Option 2', 'Option 3'];
  // useEffect(() => {
  //   axios
  //     .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
  //     .then((res) => {
  //       console.log("gettingSUBData", res.data.subsubcatagories);
  //       // setSearchedData(res.data.subsubcatagories);
  //       console.log("Abc=====", res.data.subsubcatagories);


  //       setGetAllModelsLanding(res.data.subsubcatagories); 

  //       // subcategory-Model
  //       const SubCatagoryArray = res.data.subsubcatagories.map(
  //         (data) => data.subcatagory
  //       );
  //       const uniqueOptionsSet1 = new Set(SubCatagoryArray);
  //       const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
  //       setOptions(uniqueOptionsArray1);

  //       // category-brand

  //       const SubCatagoryArray1 = res.data.subsubcatagories.map(
  //         (data) => data.catagory
  //       );
  //       const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
  //       const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
  //       setOptions1(uniqueOptionsArray11);
  //     })
  //     .catch((err) => {
  //       console.log("err====", err);
  //     });
  // }, []);

  const [newAccess, setNewAccess] = useState()

  async function getNewAccessories() {
    axios.get(`${Env.server}/api/subsubcatagory/getLandingSixRetail`)
      .then((res) => {
        setNewAccess(res.data.subsubcatagories)
        console.log("new accessories", res.data.subsubcatagories)
      })
      .catch((error) => {
        console.log("Error in fetching new Accessories", error)
      })
  }
  const [days, setDays] = useState(20);
  const [hours, setHours] = useState(13);
  const [minutes, setMinutes] = useState(20);
  const [seconds, setSeconds] = useState(11);

  useEffect(() => {
    const timer = setInterval(() => {
      // Decrease seconds
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        // If seconds reach zero, decrease minutes
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // If minutes reach zero, decrease hours
          if (hours > 0) {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          } else {
            // If hours reach zero, decrease days
            if (days > 0) {
              setDays(days - 1);
              setHours(23);
              setMinutes(59);
              setSeconds(59);
            } else {
              // Clear interval when timer reaches zero
              clearInterval(timer);
            }
          }
        }
      }
    }, 1000);

    // Clean up function to clear interval
    return () => clearInterval(timer);
  }, [days, hours, minutes, seconds]);

  const uniqueSubcategories = new Set(
    GetAllModelsLanding.map((card) => card.subcatagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];

  const uniqueSubcategoriesBrand = new Set(
    GetAllModelsLanding.map((card) => {
      if (card.catagory !== "undefined" && card.catagory !== undefined) {
        return card.catagory;
      }
    })
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const truncateTitle = (title, maxLength) => {
    const words = title.split(" ");
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(" ") + " ...";
    }
    return title;
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };



  const selectCategory = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const mainContainerStyle = {
    backgroundImage: "linear-gradient(to right, #F1DAFF, #EFBDF3)",
    // padding: '20px', // Adjust padding as needed
    // borderRadius: '10px', // Adjust border radius as needed
  };

  const [sublistContent, setSublistContent] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [currentActive, setCurrentActive] = useState(-1);

  // from here we get option which is hovered and filter data
  const handleMouseEnter = (option) => {
    let TypeData = newNav.filter(
      (item) => item.type === option
    );
    let sublistContent = TypeData[0].categories && TypeData[0].categories.map(({ category, subcategories }) => {
      return (
        category && category !== 'undefined' ?
          <Col key={category} md={4} sm={4} lg={4} xl={4} xs={4} style={{ textAlign: "initial", marginTop: '8px' }}>
            <h5
              style={{ fontWeight: "bold", fontSize: "13px", marginBottom: '0px', cursor: "pointer" }}
              className="sublist-headings"
              onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`);
              }}>
              {category}
            </h5>
            {subcategories && subcategories.slice(0, 5).map((subcategory, index) => (
              <li
                key={index}
                style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }}
                className="sublist-sub-headings hover-sublist"
                onClick={() => {
                  setIsHovered(false);
                  navigate(`/AccessoryType/${subcategory}/${option}`);
                }}>
                {subcategory}
              </li>
            ))}
            {
              [...subcategories].length > 5 && <li style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }} className="sublist-sub-headings" onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`)
              }}>See more</li>
            }
          </Col>
          : null)
    });
    setSublistContent(
      <Container>
        <Row className="sublist-container">
          {sublistContent}
        </Row>
      </Container>
    );

    setIsHovered(true); // User is hovering over a list item or sublist
  };


  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsHovered(false); // User is not hovering over a list item or sublist
  };


  // const products = [
  //   { name: 'Hands Free', image: 'https://ik.imagekit.io/cy8phtesy/kisspng-battery-charger-mobile-phone-accessories-iphone-he-earphone-5acee16928a724.5518967215235075611665.png?updatedAt=1715197875536' },
  //   { name: 'Screen Protector', image: 'https://ik.imagekit.io/cy8phtesy/pngwing.com%20(2).png?updatedAt=1715197622496' },
  //   { name: 'Wireless Charger', image: 'https://ik.imagekit.io/cy8phtesy/pngwing.com%20(3).png?updatedAt=1715197926247' },
  //   { name: 'Case', image: 'https://ik.imagekit.io/cy8phtesy/pngwing.com%20(4).png?updatedAt=1715197963497' },
  //   { name: 'Screen Protection', image: 'product2.jpg' },
  //   { name: 'Cables', image: 'product2.jpg' },
  //   { name: 'Speakers', image: 'product2.jpg' },
  //   { name: 'Headphones', image: 'product2.jpg' },
  //   { name: 'Gaming Accessories', image: 'product2.jpg' },
  //   // Add more products here
  // ];


  const [startIndex, setStartIndex] = useState(0);

  // const handleNext = () => {
  //   setStartIndex(startIndex + 4 < products.length ? startIndex + 4 : startIndex);
  // };
  const maxIndexToShow = Math.min(NavbarData.length, startIndex + 4);

  const handleNext = () => {
    if (startIndex + 4 < NavbarData.length) {
      setStartIndex(startIndex + 4);
    }
  };

  const handlePrev = () => {
    setStartIndex(startIndex - 4 >= 0 ? startIndex - 4 : 0);
  };
  const [isSideBar, setIsSideBar] = useState(false)
  // const [subMenu, setSubMenu] = useState(false);
  // const [subSubMenu, setSubSubMenu] = useState(false)
  const [expandedItems, setExpandedItems] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const toggleItem = (index) => {
    setExpandedItems(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
  const toggleSubMenu = (index) => {
    setSubMenu(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    })
  }
  return (
    <>
      <Navbar1
        setSidebar={setIsSideBar}
      />
      <div className="  row justify-content-center bannerafternavbar" style={{}}>
        {/* from here our sidebar  codes starts */}
        <div className="d-none d-md-block col-4 text-center pt-2 pr-0">
          <ul className="list-group">
            {NavbarData?.slice(0, 13).map((item, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{
                  borderBottom: "1px solid lightgray",
                  backgroundColor: `${currentActive === index ? '#F7DB03' : '#ffffff'}`,
                  borderLeft: "none",
                  borderRight: "none",
                  borderTop: "none",
                  fontSize: '13px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  cursor: "pointer"
                }}
                onMouseEnter={() => {
                  setCurrentActive(index);
                  handleMouseEnter(`${item.type}`)
                }}
                onMouseLeave={handleMouseLeave}
                onClick={() => navigate(`/AmPage3/${item.type}`)}
              >
                {item.type}
                <span
                  className="badge"
                  style={{ color: "#c5c5c5", fontSize: "13px", paddingTop: '4px', paddingBottom: '4px' }}
                >
                  &#62;
                </span>
              </li>
            ))}



          </ul>



          <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px' }}>
            <Link to="/AllTypes">
              <p style={{ cursor: "pointer", fontSize: "13px", color: "black", textDecoration: "underline" }}>See All</p>
            </Link>
          </div>
          {/* Sublist */}
          <div
            className="sublist"
            style={{
              display: isHovered ? "block" : "none",
              position: "absolute",
              left: "100%",
              top: 0,
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "5px",
              width: "780px",
              height: "390px",
              zIndex: 999,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {sublistContent}
          </div>
        </div>
        {/* sidebar for small screens */}
        <div className={`mobileSidebar ${isSideBar ? 'show' : ''}`}>
          <div className="" style={{ display: 'flex', justifyContent: 'end', padding: '20px' }}>
            <p style={{ fontSize: '25px', color: 'white' }}
              onClick={() => setIsSideBar(false)}
            >X</p>
          </div>
          <div className="sidebar-content">
            {newNav && newNav.map((item, index) => (
              <div key={index} className={`item ${expandedItems[index] ? 'expanded' : 'collapsed'}`}>
                <div className="header" onClick={() => toggleItem(index)}>
                  <h1
                    onClick={() => {
                      setIsSideBar(false)
                      navigate(`/AmPage3/${item.type}`)
                    }}
                  >{item.type}</h1>
                  <p style={{ fontSize: '30px' }}>{expandedItems[index] ? '-' : '+'}</p>
                </div>
                <div className={`sub-content ${expandedItems[index] ? 'show' : 'hide'}`}>
                  {item.categories && item.categories.map((data, subIndex) => (
                    <div key={subIndex} className="category">
                      <div className="category-header" >
                        <p
                          onClick={() => {
                            setIsSideBar(false)
                            navigate(`/AmPage4/${item.type}/${data.category}`)
                          }}
                        >{data.category}</p>
                        <p style={{ fontSize: '23px' }} onClick={() => toggleSubMenu(subIndex)}>{subMenu[subIndex] ? '-' : '+'}</p>
                      </div>
                      <div className={`sub-sub-content ${subMenu[subIndex] ? 'show' : 'hide'}`}>
                        {data.subcategories.map((category, subSubIndex) => (
                          <p key={subSubIndex}
                            onClick={() => {
                              setIsSideBar(false)
                              navigate(`/AccessoryType/${category}/${item.type}`)
                            }}
                          >{category}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-8 md:text-center pt-2 pr-0">
          <div className="main d-flex align-items-center justify-content-center pb-4">
            <div className="card bg-white m-3 pl-5 pr-5 banner-search-card">
              <div className="card-body">
                <h2
                  className="card-title text-start banner-card-main-text"
                  style={{
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  Find accessories for your device
                </h2>
                <p
                  className="card-text text-start banner-card-secondary-text"
                  style={{ fontSize: "15px" }}
                >
                  Enter phone, tablet or wearable
                </p>

                <div className="input-group search-field-banner">
                  {/* <input type="text" className="form-control" placeholder="Search products" /> */}
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "400px",

                    }}
                  >
                    <TextField
                      id="search"
                      className=""
                      label="e.g. iPhone 15, Galaxy S24, iPad Pro, Apple Watch"
                      variant="outlined"
                      value={searchText}
                      ref={inputRef}
                      onChange={handleInputChange}
                      onClick={handleClick}
                      sx={{ width: "100%", border: "1px solid black", borderRadius: "5px", zIndex: '0' }} // Set the width of the TextField
                      InputLabelProps={{
                        style: {
                          fontStyle: "italic", // Set label to italic
                          fontSize: "11px", // Set label font size
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon style={{ margin: "0" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Popper
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      style={{ width: "400px" }}
                      className="search-bar-popper"
                      ref={popperRef}
                    >
                      <Paper
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          width: "100%",

                        }}
                      >
                        <List>
                          {options
                            .filter((option) =>
                              option?.toLowerCase().includes(searchText)
                            )
                            .map((option, index) => (
                              <Link
                                to={`/Model/brand/${option}`}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <ListItemButton
                                  key={index}
                                  onClick={() => setSearchText(option)}

                                >
                                  <ListItemText primary={<span style={{ fontSize: "11px" }}>{option}</span>} />
                                  {/* primary={option} style={{ fontSize: "smaller" }} */}
                                </ListItemButton>
                              </Link>
                            ))}
                        </List>

                      </Paper>
                    </Popper>
                  </div>
                  {/* <button className="btn btn-outline-primary" type="button">
                                        <i className="bi bi-search"></i>
                                    </button> */}
                </div>
                <Link to='/AllTypes'>
                  <p className="card-text text-start mb-0">
                    <a
                      href="#"
                      className="text-primary banner-card-secondary-text"
                      style={{
                        fontSize: "13px",
                        background: "none",
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                    >
                      Can't see your device?
                    </a>
                  </p>
                </Link>
                <Link to='/Contactus'>
                  <p className="card-text text-start">
                    <a
                      href="#"
                      className="text-primary banner-card-secondary-text"
                      style={{
                        fontSize: "13px",
                        background: "none",
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                    >
                      Need help identifying your device?
                    </a>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing-page-tag-spacing" style={{ marginTop: '50px', padding: "10px", borderLeft: "8px solid #F7DB03" }}>
        <h4>CATEGORIES</h4>
      </div>

      <CategorySlider NavbarData={NavbarData} getAsset={getAsset} startIndex={startIndex} maxIndexToShow={maxIndexToShow} />

      <div
        className="landing-page-tag-spacing"
        style={{

          marginTop: "50px",

          padding: "10px",
          borderLeft: "8px solid #F7DB03",
        }}
      >
        <h4>ACCESSORIES</h4>
      </div>

      <Container style={{ marginTop: "1rem" }}>
      <Row className="justify-content-center">
        {newAccess && newAccess.map((product, index) => (
          <Col md={6} lg={4} key={index} className="mb-4 " style={{ cursor: 'pointer' }}>
            <Card
            className="new-acc-cards"
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '1rem',
                textAlign: 'center'
              }}
            >
              <Card.Body>
                <Card.Title style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {product.subsubcatagory}
                </Card.Title>
                <Card.Img
                  variant="top"
                  src={getAsset(product.imageSubsubCatagory[0])}
                  alt="product image"
                  className="img-fluid"
                  style={{ objectFit: "cover", width: '100%', height: "auto", marginBottom: "1rem" }}
                />
                <Card.Text
                  style={{
                    fontWeight: "bold",
                    color: "#FF0B09",
                    marginTop: "1rem",
                    fontSize: "18px"
                  }}
                >
                  £{product.productPrice}
                </Card.Text>
              </Card.Body>
              <Button
                variant="primary"
                style={{ backgroundColor: "#000000", border: "none" }}
                onClick={() => navigate(`/AwmPageB/${product.subsubcatagory}`)}
              >
                View Accessories
              </Button>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>



      <div
        className="landing-page-tag-spacing"
        style={{
          marginTop: '40px',
          padding: "10px",
          borderLeft: "8px solid #F7DB03 ",
        }}
      >
        <h4>BRAND-TYPES</h4>
      </div>

      <div
        class="container-fluid"
      // style={{ backgroundColor: "rgba(219, 36, 27, 0.1)" }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: 1,
            justifyContent: "center",

          }}
        >
          {uniqueSubcategoriesArrayBrand.map((data, index) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              xl={2} // Set extra-large screen size to accommodate 5 cards in a row
              key={index}
              sx={{
                // margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                height: "6rem",
                cursor: "pointer",
                // width: "300px"
              }}
              onClick={() => navigate(`/BrandPage/${data}`)}
            >
              <Grid
                className="brandcardfont brandcard"
                container
                alignItems="center"
                sx={{
                  height: "100%",
                  // padding: 2,
                  borderRadius: "8px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "#F8F6ED",
                }}
              >
                <Grid
                  className="brandcard"
                  item
                  xs={7}
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {data}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

      </div>

      <div className="container-fluid pt-3">
        <div
          className="landing-page-tag-spacing"
          style={{
            marginTop: '40px',

            padding: "10px",
            borderLeft: "8px solid #F7DB03 ",
          }}
        >
          <h4>MODELS</h4>
        </div>

        {/* OLD BANNER CARDS */}
        <div className="row justify-content-center mb-5" style={{ margin: '0px auto', alignItems: 'center' }} >
          <MainModel link={mainModel?.link} title={mainModel?.title} image={mainModel?.image} description={mainModel?.description} />

          <div className="col-lg-5">
            <div>
              {modelsData && <ModelSlider ModelsData={modelsData} />}
              {/* <ModelSlider ModelsData={ModelsData} getAsset={getAsset} /> */}
            </div>
          </div>

        </div>


      </div>

      {/* LIMITED TIME OFFER */}
      <div
        className="landing-page-tag-spacing"
        style={{
          marginTop: '40px',

          padding: "10px",
          borderLeft: "8px solid #F7DB03",

        }}
      >
        <h4>LIMITED TIME OFFER</h4>
      </div>
      {LTOData &&
        <LimitedTimeOffers ltoData={LTOData} />
      }

      <div>
        <Container style={{ padding: "10px" }}>
          <Row className="align-items-center justify-content-center"></Row>
        </Container>
      </div>

      <div
        className="landing-page-tag-spacing"
        style={{
          marginTop: '40px',

          padding: "10px",
          borderLeft: "8px solid #F7DB03 ",
        }}
      >
        <h4>NEW ARRIVALS</h4>
      </div>

      <div className="container-fluid pt-3">
        <div className="row justify-content-center mb-5">
          <div className="col-lg-5" data-toggle="modal"
            data-target="#exampleModalLong1" onClick={() => setDataNumber(1)} style={{}}>
            <div className="card bg-dark text-white" style={{ position: 'relative', height: '600px' }}>
              <img className="card-img" src={getAsset(NewArrivalOne && NewArrivalOne.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
              <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bolder' }}>{NewArrivalOne.type}</p>
                <p className="card-text" style={{ margin: '0', marginTop: '10px' }}>{NewArrivalOne.content}</p>
                <button onClick={() => {
                  navigate(`/AmPage3/${NewArrivalOne && NewArrivalOne.type}`);
                  window.location.reload();
                }} className="align-start text-black shopbutton" style={{ backgroundColor: "", borderRadius: "2px", padding: "5px", borderBottom: '1px solid #ffffff', width: '20%', marginBottom: '15px', marginTop: '15px', border: "1px solid black" }}>Shop Now</button>
              </div>
            </div>

          </div>
          <div className="col-lg-5">
            <div className="card bg-dark text-white mb-0" data-toggle="modal"
              data-target="#exampleModalLong1" onClick={() => setDataNumber(2)} style={{ position: 'relative', height: '300px' }}>
              <img className="card-img" src={getAsset(NewArrivalTwo && NewArrivalTwo.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
              <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bolder' }}>{NewArrivalTwo && NewArrivalTwo.type}</p>
                <p className="card-text " style={{ margin: '0', marginTop: '10px' }}>{NewArrivalTwo && NewArrivalTwo.content}</p>
                <button className="align-start text-black shopbutton" onClick={() => navigate(`/AmPage3/${NewArrivalTwo && NewArrivalTwo.type}`)} style={{ backgroundColor: "", borderRadius: "2px", padding: "5px", borderBottom: '1px solid #ffffff', width: '20%', marginBottom: '15px', marginTop: '15px', border: '1px solid black' }}>Shop Now</button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6" data-toggle="modal"
                data-target="#exampleModalLong1" onClick={() => setDataNumber(3)} style={{}}>
                <div className="card bg-dark text-white" style={{ position: 'relative', height: '290px' }}>
                  <img className="card-img" src={getAsset(NewArrivalThree && NewArrivalThree.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
                  <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bolder' }}>{NewArrivalThree && NewArrivalThree.type}</p>
                    <p className="card-text" style={{ margin: '0', marginTop: '10px' }}>{NewArrivalThree && NewArrivalThree.content}</p>
                    <button className="align-start text-black shopbutton" onClick={() => navigate(`/AmPage3/${NewArrivalThree && NewArrivalThree.type}`)} style={{ backgroundColor: "", borderRadius: "2px", padding: "5px", borderBottom: '1px solid #ffffff', width: '50%', marginBottom: '15px', marginTop: '15px', border: '1px solid black' }}>Shop Now</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card bg-dark text-white" data-toggle="modal"
                  data-target="#exampleModalLong1" onClick={() => setDataNumber(4)} style={{ position: 'relative', height: '290px' }}>
                  <img className="card-img" src={getAsset(NewArrivalFour && NewArrivalFour.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
                  <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bolder' }}>{NewArrivalFour && NewArrivalFour.type}</p>
                    <p className="card-text" style={{ margin: '0', marginTop: '10px' }}>{NewArrivalFour && NewArrivalFour.content}</p>
                    <button className="align-start text-black shopbutton" onClick={() => navigate(`/AmPage3/${NewArrivalFour && NewArrivalFour.type}`)} style={{ backgroundColor: "", borderRadius: "2px", padding: "5px", borderBottom: '1px solid #ffffff', width: '50%', marginBottom: '15px', marginTop: '15px', border: '1px solid black' }}>Shop Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



      <HotSale />
      {/* <div style={{ backgroundColor: "#F8F6ED" }}>
        <Container style={{ padding: "10px" }}>
          <Row className="align-items-center justify-content-center">
            <Col md={4}>
              <img
                src="https://ik.imagekit.io/cy8phtesy/Samsung_S22_Unlocked_128_GB_dMAatsMh7y.png?updatedAt=1715691822613"
                alt="product"
                width="50%"
                height="auto"
              />
            </Col>
            <Col md={4}>
              <div>
                <p>Redefining Excellence</p>
                <h4 style={{ fontWeight: "bold" }}>Samsung Ultra Pro Max</h4>
                <p>
                Samsung Ultra represents technological innovation in Samsung's product lineup. Whether it's the latest smartphone model or state-of-the-art home appliance, Samsung Ultra promises an unparalleled experience characterized by exceptional performance and intuitive features.
                </p>
                <Link to="/AllTypes">
                <button
                  style={{
                    padding: "8px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Shop Now
                </button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* <Container className="align-items-center justify-content-between mt-5 mb-5">
       
        <Row className="mb-4 justify-content-center">
          <Col xs={5} md={5}>
            <div className="input-group">
              <input
                type="email"
                className="form-control custom-input"
                placeholder="Enter your email"
                value={emailSubscribe}
                onChange={(e)=>setEmailSubscribe(e.target.value)}
                aria-label="Your email"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary custom-button"
                  onClick={handleSubscribe}
                  type="button"
                  style={{
                    backgroundColor: "#000000",
                    border: "1px solid #212059",
                    fontWeight: "400",
                  }}
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}


      <section className="search-container" style={{ marginTop: "1rem", }}>
        <div className="left-div">
          <FaEnvelope className="icon" />
          <div className="formargin">
            <span className="text fs-3 fw-bold ">GET 10% OFF</span>
            <p>Sign up to our newsletter for access to our exclusive deals and 10% off your first order.</p>
          </div>
        </div>
        <div className="right-div">
          <Link to="/Singup">
            <div>
              <button
                className="btn btn-primary"
                // onClick={handleSubscribe}
                type="button"
                style={{
                  padding: window.innerWidth <= 550 ? ".6em .8em" : ".8em 2rem",
                  backgroundColor: "#000000",
                  border: "1px solid #212059",
                  fontWeight: "400",
                  marginBottom: "4vh"
                }}
              >
                Sign Up
              </button>
            </div>
          </Link>
        </div>
      </section>

      <ToastContainer />
    </>
  );
};
export default LandingPage;
