import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css"; // Import CSS file containing styles for the component
import axios from "axios";
import Env from "../Environment/Env";
import "bootstrap/dist/css/bootstrap.min.css";
import { Collapse } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import MenuIcon from '@mui/icons-material/Menu';

// import logo from "./logo.png";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, Card, CardContent } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import SideBar from "../LandingPage/Sidebar";
import { FaBars } from 'react-icons/fa';

const Main1 = ({ setSidebar }) => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);








  const inputRef = useRef(null);
  const popperRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const [isEnabled, setIsEnabled] = useState(true);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Check user's login status and role after component mount
    checkUserLogin();
  }, []);

  const checkUserLogin = () => {
    // Simulated logic to check user's login status and role
    const user = localStorage.getItem("user");
    if (user && user.role === "seller" && user.id) {
      setIsLoggedIn(true);
      setUserRole(user.role);
    }
  };

  const handleLogout = () => {
    // Handle logout logic here
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    setUserRole("");
    navigate("/login"); // Redirect to login page after logout
  };

  useEffect(() => {
    axios
      .get(`${Env.server}/api/traderOnOff/GetShowHide`)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setIsEnabled(res.data.data[0].TradeShow);
        } else {
          console.log(
            "No data found or data is in an unexpected format",
            res.data
          );
        }
      })
      .catch((err) => console.log("err===>>>", err));
  }, []);

  const handleTrackOrderClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalStatusShow(false);
  };

  const [ModalStatusShow, setModalStatusShow] = useState(false);
  const [SelectedOrderStatus, setSelectedOrderStatus] = useState({});

  const [OrderNumber, setOrderNumber] = useState("");
  const handleTrackOrder = () => {
    console.log("OrderNumber====", OrderNumber);
    axios
      .get(`${Env.server}/api/order/trackOrderStatus/${OrderNumber}`)
      .then((res) => {
        console.log("res====", res.data.orders);
        setSelectedOrderStatus(res.data.orders);
        setModalStatusShow(true);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // GetAllSubSubCatagory();
    GetAllTypes();

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };

  const GetAllTypes = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        let resp = res.data.allType;
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", resp)
        const typesArray = resp.map((item) => item.type);

        console.log("typesArray====", typesArray);
        setUniqueTypes(typesArray);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  // const GetAllSubSubCatagory = () => {
  //   axios
  //     .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
  //     .then((res) => {
  //       let resp = res.data.subsubcatagories;
  //       // Extracting unique types
  //       // const types = Array.from(new Set(resp.map(item => item.typeName)));
  //       // console.log("types====", types)
  //       // setUniqueTypes(types);
  //       setAccessoriesData(resp);
  //       const CatagoryArray = res.data.subsubcatagories.map(
  //         (data) => data.catagory
  //       );
  //       const SubCatagoryArray = res.data.subsubcatagories.map(
  //         (data) => data.subcatagory
  //       );
  //       const SubSubCatagoryArray = res.data.subsubcatagories.map(
  //         (data) => data.subsubcatagory
  //       );

  //       // Create a Set from the CatagoryArray
  //       const uniqueOptionsSet = new Set(CatagoryArray);
  //       const uniqueOptionsArray = Array.from(uniqueOptionsSet);

  //       // Create a Set from the CatagoryArray
  //       const uniqueOptionsSet1 = new Set(SubCatagoryArray);
  //       const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);

  //       // Create a Set from the CatagoryArray
  //       const uniqueOptionsSet2 = new Set(SubSubCatagoryArray);
  //       const uniqueOptionsArray2 = Array.from(uniqueOptionsSet2);
  //       setOptions(uniqueOptionsArray);
  //       setOptions1(uniqueOptionsArray1);
  //       setOptions2(uniqueOptionsArray2);
  //     })
  //     .catch((err) => {
  //       console.log("err====", err);
  //     });
  // };

  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [NewArrivalOne, setNewArrivalOne] = useState([]);
  const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
  const [NewArrivalThree, setNewArrivalThree] = useState([]);
  const [NewArrivalFour, setNewArrivalFour] = useState([]);
  const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);

  const [sublistContent, setSublistContent] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [NavbarData, setNavbarData] = useState([]);

  useEffect(() => {
    getAllNavbarData();
    getAllData();
  }, []);

  const getAllNavbarData = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        console.log("gettingNavbarMainHeadings", res.data.allType);
        setNavbarData(res.data.allType);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const getAllData = () => {
    axios
      .post(`${Env.server}/api/retailnewarival/getAll`)

      .then((res) => {
        console.log("API response:", res);
        const data = res.data.data;
        if (data && data.length > 0) {
          const firstItem = data[0];
          console.log("Abresc===", firstItem);

          setNewArrivalOne(firstItem?.One?.[0] || "Default Value for One");
          setNewArrivalTwo(firstItem?.Two?.[0] || "Default Value for Two");
          setNewArrivalThree(
            firstItem?.Three?.[0] || "Default Value for Three"
          );
          setNewArrivalFour(firstItem?.Four?.[0] || "Default Value for Four");
        } else {
          console.warn("No data found");
        }
      })

      .catch((err) => {
        console.log("err====", err);
      });
  };

  // const options = ['Option 1', 'Option 2', 'Option 3'];
  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        console.log("gettingSubdatafromhere", res.data.subsubcatagories);
        setGetAllModelsLanding(res.data.subsubcatagories);
        setAccessoriesData(res.data.subsubcatagories);
        // subcategory-Model
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
        setOptions1(uniqueOptionsArray1);

        // category-brand

        const SubCatagoryArray1 = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
        const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
        setOptions(uniqueOptionsArray11);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  }, []);

  const uniqueSubcategories = new Set(
    GetAllModelsLanding.map((card) => card.subcatagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];

  const uniqueSubcategoriesBrand = new Set(
    GetAllModelsLanding.map((card) => card.catagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

  // Function to handle hover over a category
  const handleMouseEnter = (option) => {
    let TypeData = GetAllModelsLanding.filter(
      (item) => item.typeName === option
    );

    let categories = {}; // Object to store categories and their corresponding subcategories

    // Populate categories object
    TypeData.forEach((item) => {
      if (!categories[item.catagory]) {
        categories[item.catagory] = [item.subcatagory];
      } else {
        categories[item.catagory].push(item.subcatagory);
      }
    });

    // Render sublist content dynamically
    let sublistContent = Object.entries(categories).map(
      ([category, subcategories]) => (
        <Col
          key={category}
          md={4}
          sm={4}
          lg={4}
          xl={4}
          xs={4}
          style={{ textAlign: "initial", marginTop: "8px" }}
        >
          <h5
            style={{
              fontWeight: "bold",
              fontSize: "13px",
              marginBottom: "0px",
              cursor: "pointer",
            }}
            className="sublist-headings"
            onClick={() => {
              setIsHovered(false);
              navigate(`/AmPage4/${option}/${category}`);
            }}
          >
            {category}
          </h5>
          {[...subcategories].slice(0, 5).map(
            (
              subcategory,
              index // Convert Set to array and remove duplicates
            ) => (
              <li
                key={index}
                style={{
                  listStyle: "none",
                  fontSize: "11px",
                  cursor: "pointer",
                }}
                className="sublist-sub-headings hover-sublist"
                onClick={() => {
                  setIsHovered(false);
                  navigate(`/AccessoryType/${subcategory}/${option}`);
                }}
              >
                {subcategory}
              </li>
            )
          )}
          {[...subcategories].length > 5 && (
            <li
              style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }}
              className="sublist-sub-headings"
              onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`);
              }}
            >
              See more
            </li>
          )}
        </Col>
      )
    );

    // Set the rendered sublist content
    setSublistContent(
      <Container>
        <Row className="sublist-container">{sublistContent}</Row>
      </Container>
    );

    setIsHovered(true); // User is hovering over a list item or sublist
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsHovered(false); // User is not hovering over a list item or sublist
  };
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <div
        className="pt-2 topnavlinks"
        style={{ color: "#fff", backgroundColor: "#000" }}
      >
        <div
          className="d-flex justify-content-center responsive-text-container"
          style={{ gap: "15px" }}
        >
          <Link to="/Aboutus">
            <p
              className="responsive-text"
              style={{ fontSize: "13px", margin: "0px" }}
            >
              About
            </p>
          </Link>
          <Link to="/Contactus">
            <p
              className="responsive-text"
              style={{ fontSize: "13px", margin: "0px" }}
            >
              Contact Us
            </p>
          </Link>
          <p
            onClick={handleTrackOrderClick}
            className="responsive-text"
            style={{ fontSize: "13px", cursor: "pointer", margin: "0px" }}
          >
            Track My Order
          </p>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton onClick={() => setShowModal(false)}>
              <Modal.Title>Track Your Order</Modal.Title>
            </Modal.Header>
            {ModalStatusShow ? (
              <>
                <Modal.Body>
                  {/* Add the contents of your modal here */}
                  {SelectedOrderStatus?.orderNumber ? (
                    <>
                      <h5 style={{ color: "green", fontWeight: "bold" }}>
                        Status: {SelectedOrderStatus?.readyStatus}
                      </h5>
                      <h5>Order Number: {SelectedOrderStatus?.orderNumber}</h5>
                      <h5>
                        Payment Status: {SelectedOrderStatus?.paymentStatus}
                      </h5>
                      <h5>Payment Via: {SelectedOrderStatus?.paymentType}</h5>
                      <h5>Postal Code: {SelectedOrderStatus?.postalCode}</h5>
                      <h5>Order Date: {SelectedOrderStatus?.orderPlaceDate}</h5>
                      <h5>
                        Customer Name: {SelectedOrderStatus?.customerName}
                      </h5>
                      <h5>
                        Customer Email: {SelectedOrderStatus?.customerEmail}
                      </h5>
                      <h5>Address: {SelectedOrderStatus?.customerAddress}</h5>
                      <h5>
                        Total Items: {SelectedOrderStatus?.productInfo.length}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h4 style={{ color: "red", fontWeight: "bold" }}>
                        No Order Found
                      </h4>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <>
                <Modal.Body>
                  {/* Add the contents of your modal here */}
                  <p>This is where you can track your order...</p>
                  <input
                    type="text"
                    placeholder="#123456789"
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    style={{ border: "1px solid transparent" }}
                    onClick={handleTrackOrder}
                  >
                    Track
                  </Button>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>
          {/* <p style={{ fontSize: "14px" }}>My Account</p> */}

          {localStorage.getItem("role") === "admin" ? (
            <>
              <p
                onClick={() =>
                (window.location.href =
                  `${Env.dashboard}`)
                }
                className="text-center text-black responsive-text"
                style={{
                  fontSize: "13px",
                  // backgroundColor: "#DB241B",
                  // backgroundColor: '#F8F9FA',
                  // border: '1px solid #DEE2E6',
                  // maxWidth: "90px",
                  // height: "30px", // "Height" should be changed to "height"
                  // border: "none",
                  // padding: "5px",
                  // borderRadius: "5px",
                  margin: "0px",
                }}
              >
                <>My Account</>
              </p>
            </>
          ) : (
            <>
              {localStorage.getItem("role") === "trader" ? (
                <Link to={"/UserDashboad"}>
                  <p
                    className="text-center text-black responsive-text"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <>Trade Dashboard</>
                  </p>
                </Link>
              ) : (
                <>
                  {isEnabled === true ? (
                    <>
                      <Link to={"/TradeSignup"}>
                        <p
                          className="text-center text-white responsive-text"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          <>Trade</>
                        </p>
                      </Link>
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </div>


      <Navbar expand="lg" className=" pt-2 navbar-padding d-flex jsustify-content-between" style={{ borderBottom: '1px solid lightgray', backgroundColor: '#F7DB03' }}>



        <Container fluid className="pr-0 pl-0 ">
          {/*       
        {showSidebarIcon &&  
          <div className="menu-icon" style={{cursor:'pointer'}} onClick={()=>{setShowSideBar(prev=>!prev)}} >
            <FontAwesomeIcon style={{fontSize:'25px',margin:'5px 15px'}} icon={showSideBar ? faTimes : faBars} />
          </div>  
        } */}


          {/* navbar */}









          {/* this is code for our logo */}
          {/* <div >
            <MenuIcon
              style={{
                fontSize: '250px',
              }}
            />
          </div> */}

          <Navbar bg="transparent" expand="lg" className="custom-navbar" style={{width:'100%'}}>
            <Navbar.Brand href="/" style={{ display: 'flex', alignItems: 'center',width:'100%',flex:'1' }}>
              <div>
                <img
                  src={Env.picLogo}
                  className="d-inline-block align-top"
                  alt="logo"
                  style={{
                    width: '150px',
                    height: '50px',
                    ...(window.innerWidth <= 768 ? { width: '140px' } : {}),
                    ...(window.innerWidth <= 576 ? { width: '120px' } : {}),
                    ...(window.innerWidth <= 400 ? { width: '100px' } : {}),
                  }}
                />
              </div>
              <div
                className="d-md-none"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto', // Pushes the search bar to the rightmost edge
                  marginTop: '8px', // Adds margin to push the search bar down
                }}
              >
                <input
                  type="text"
                  style={{
                    border: '1px solid rgba(85, 85, 85, 1)',
                    fontStyle: 'italic',
                    fontSize: '14px',
                    borderRadius: '10px',
                    width: '100%',
                  }}
                  placeholder="By device, brand or item.."
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                  ref={inputRef}
                />
              </div>
            </Navbar.Brand>
          </Navbar>

          {/* { (!showSidebarIcon) && <Navbar.Toggle aria-controls="navbarScroll"   />} */}
          <Navbar.Collapse id="navbarScroll" >
            <Form className="m-auto d-flex" style={{}}>
              <div className="">
                <div className="input-group search-bar-lg" style={{ margin: '0px' }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="By device, brand or item.."
                    value={searchText}
                    onChange={handleInputChange}
                    onClick={handleClick}
                    style={{
                      border: "1px solid rgba(85, 85, 85, 1)",
                      fontStyle: "italic",
                      fontSize: "14px",
                    }}
                    ref={inputRef}
                  />
                  <div className="input-group-append">
                    <span
                      style={{
                        border: "1px solid rgba(85, 85, 85, 1)",
                        fontSize: "14px",
                      }}
                      className="input-group-text"
                    >
                      <i className="bi bi-search"></i>
                    </span>
                  </div>
                </div>
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  style={{}}
                  ref={popperRef}
                >
                  <Paper
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      width: "auto",
                    }}
                  >
                    <List>
                      {/* Assuming options2, options1, and options are defined */}
                      <ListItemButton disabled>
                        <ListItemText primary="Accessories" />
                      </ListItemButton>
                      {options2
                        .filter((option) =>
                          option
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/AwmPageB/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton
                              onClick={() => setSearchText(option)}
                            >
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                      <ListItemButton disabled>
                        <ListItemText primary="Models" />
                      </ListItemButton>
                      {options1
                        .filter((option) =>
                          option
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/Model/brand/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton
                              onClick={() => setSearchText(option)}
                            >
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                      <ListItemButton disabled>
                        <ListItemText primary="Brand" />
                      </ListItemButton>
                      {options
                        .filter((option) =>
                          option
                            ?.toLowerCase()
                            .includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/BrandPage/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton
                              onClick={() => setSearchText(option)}
                            >
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                    </List>
                  </Paper>
                </Popper>
              </div>
            </Form>
            <div className="cart-item-icon ml-3">
              <Link to={"/Cart1"} className="nav-link">
                <i className="bi bi-cart" style={{ fontSize: "25px" }}></i>
                <span className="position-absolute top-40 translate-middle badge rounded-pill" style={{ background: "#000" }} >
                  {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                    JSON.parse(localStorage.getItem("selectedCartItems"))
                      .length) ||
                    0}
                  <span className="visually-hidden">items in cart</span>
                </span>
              </Link>
            </div>
            {!localStorage.getItem("role") ? (
              <>
                <Link to="/Singin">
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      padding: "5px 0px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      textDecoration: "underline",
                    }}
                  >

                    <h6
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      Login
                    </h6>
                  </div>
                </Link>
                <span></span>
                <Link to="/Singup">
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      padding: "5px 5px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      textDecoration: "underline",
                    }}
                  >
                    <h6
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        textAlign: "center",
                        textWrap: "nowrap",
                      }}
                    >
                      Sign Up
                    </h6>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <Link to="/UserDashboard">
                  <div
                    className="login-credential-buttons"
                    style={{
                      cursor: "pointer",
                      border: "1px solid rgba(85, 85, 85, 0.5)",
                      padding: "5px 13px",
                      borderRadius: "8px",
                      margin: "0 15px 0 0",
                      backgroundColor: "white",
                    }}
                  >
                    <h6
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        textAlign: "center",
                        textWrap: "nowrap",
                      }}
                    >
                      Dashboard
                    </h6>
                  </div>
                </Link>
              </>
            )}

            {/* {localStorage.getItem("role") === "admin" ? (
  <>
    <button
      onClick={() =>
        (window.location.href =
          "https://backoffice.mobilebitz.co.uk/")
      }
      className="text-center text-black"
      style={{
        
        background: 'none',
        
        border: 'none',
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      {localStorage.getItem("id") ? (
        <span style={{ marginLeft: '10px', fontSize: '25px' }}>
          <i className="fa fa-user"></i>
        </span>
      ) : (
        <img
          src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
          alt="Login/Signup"
          style={{ width: '25px', height: '25px', marginLeft: '10px' }}
        />
      )}
    </button>
  </>
) : (
  
  <>
    <Link to={"/UserDashboad"}>
      <button
        className="text-center text-black"
        style={{
          
          background: 'none',
         
          border: 'none',

          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {localStorage.getItem("id") ? (
          <span style={{ marginLeft: '10px', fontSize: '25px' }}>
            <i className="fa fa-user"></i>
          </span>
        ) : (
          <img
            src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
            alt="Login/Signup"
            style={{ width: '25px', height: '25px', marginLeft: '10px' }}
          />
        )}
      </button>
    </Link>
  </>
)} */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* second navbar for small screens */}
      <div
        className="d-flex d-md-none"
        style={{
          backgroundColor: 'black',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Nav className="">
          <div
            style={{
              cursor: 'pointer',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
            className="d-block d-md-none"
            onClick={() => {
              console.log("clicking done");
              setSidebar((prev) => !prev);
            }}
          >
            <FaBars size={30} color="white" />
          </div>
        </Nav>

        <div style={{ display: 'flex', alignItems: 'center',gap:'5px' }}>
          {!localStorage.getItem("role") ? (
            <>
              <Link to="/Singin" style={{ textDecoration: 'none' }}>
                <div
                  className="login-credential-buttons"
                  style={{
                    cursor: "pointer",
                    borderRadius: "8px",
                    margin: "0 15px 0 0",
                    textDecoration: "none",
                    color: "white",
                    padding: "10px 20px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0056b3"}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#007bff"}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    Login
                  </h6>
                </div>
              </Link>
              <Link to="/Singup" style={{ textDecoration: 'none' }}>
                <div
                  className="login-credential-buttons"
                  style={{
                    cursor: "pointer",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    margin: "0 15px 0 0",
                    textDecoration: "underline",
                    color: "white",
                    transition: "background-color 0.3s",
                    
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#218838"}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#28a745"}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    Sign Up
                  </h6>
                </div>
              </Link>
              <div className="cart-item-icon ml-3">
                <Link to={"/Cart1"} className="nav-link">
                  <i className="bi bi-cart" style={{ fontSize: "25px", color: 'white' }}></i>
                  <span className="position-absolute top-40 translate-middle badge rounded-pill" style={{ background: "#000" }} >
                    {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                      JSON.parse(localStorage.getItem("selectedCartItems"))
                        .length) ||
                      0}
                    <span className="visually-hidden">items in cart</span>
                  </span>
                </Link>
              </div>
            </>
          ) : (
            <>
              <Link to="/UserDashboard" style={{ textDecoration: 'none' }}>
                <div
                  className="login-credential-buttons"
                  style={{
                    cursor: "pointer",
                    border: "1px solid rgba(85, 85, 85, 0.5)",
                    borderRadius: "8px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#f8f9fa";
                    e.currentTarget.style.color = "#007bff";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "white";
                    e.currentTarget.style.color = "black";
                  }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      padding:'5px',
                      textAlign: "center",
                      margin: 0,
                      color: "white",
                    }}
                  >
                    Dashboard
                  </h6>
                </div>
              </Link>
              <div className="cart-item-icon ml-3">
                <Link to={"/Cart1"} className="nav-link">
                  <i className="bi bi-cart" style={{ fontSize: "25px", color: 'white' }}></i>
                  <span className="position-absolute top-40 translate-middle badge rounded-pill" style={{ background: "#000" }} >
                    {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                      JSON.parse(localStorage.getItem("selectedCartItems"))
                        .length) ||
                      0}
                    <span className="visually-hidden">items in cart</span>
                  </span>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      {/* <main className="cd__main">
        <header className={isMenuOpen ? "dark" : "dark"}>
          <nav role="navigation">
            <a
              href="javascript:void(0);"
              className={`ic menu ${isMenuOpen ? "hidden" : ""}`}
              onClick={toggleMenu}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </a>
            <a
              href="javascript:void(0);"
              className={`ic close ${isMenuOpen ? "" : "hidden"}`}
              onClick={toggleMenu}
            ></a>
            <ul style={{margin: '0px', maxWidth: "100%", display: 'flex', justifyContent: 'center'}} className={`main-nav ${isMenuOpen ? "open" : ""}`}>
              {AccessoriesData?.slice(0, 6).map((data, index) => (
                <React.Fragment key={index}>
                  <li className="top-level-link">
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/AmPage3/${uniqueTypes[index]}`);
                        window.location.reload();
                      }}
                      className="mega-menu"
                    >
                      <span>{uniqueTypes[index]}</span>
                    </Link>
                    <div className="sub-menu-block">
                      <div className="row">
                        {options.map((item, index1) => {
                          // Filter data based on category and type
                          const filteredData = AccessoriesData.filter(
                            (item1) =>
                              item1.catagory === item &&
                              item1.typeName === uniqueTypes[index]
                          );

                          // Check if filtered data is not empty
                          if (filteredData.length > 0) {
                            // Remove duplicates
                            const uniqueSubcategories = [
                              ...new Set(
                                filteredData.map((item) => item.subcatagory)
                              ),
                            ];
                            return (
                              <div
                                className="col-md-4 col-lg-4 col-sm-4"
                                key={index1}
                              >
                                <h2
                                  className="sub-menu-head"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link
                                    to={`/AmPage4/${uniqueTypes[index]}/${item}`}
                                  >
                                    {item} {uniqueTypes[index]}
                                  </Link>
                                </h2>
                                <ul className="sub-menu-lists">
                                  {uniqueSubcategories.slice(0,7).map(
                                    (subCategory, subIndex) => (
                                      <li
                                        key={subIndex}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Link
                                          to={`/AccessoryType/${encodeURIComponent(
                                            subCategory
                                          )}/${uniqueTypes[index]}`}
                                        >
                                          {" "}
                                          {subCategory} {uniqueTypes[index]}{" "}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                  <Link to="/AccessoryType/IPhone13/ScreenProtector">
                                        <p style={{fontWeight: '500', textDecoration: 'underline'}}>See More</p>
                                 </Link>
                                </ul>
                              </div>
                            );
                          } else {
                            return null; // Don't render if filtered data is empty
                          }
                        })}
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              ))}
            </ul>
            
          </nav>
        </header>
      </main> */}
    </>
  );
};

export default Main1;
