import React from "react";
import { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Env from '../Environment/Env';
import './Support.css';
const Support = () => {
  useEffect(() => {

    class Menu {
      //Constructor for Menu class
      constructor(_name, _activateID, _fa) {
        this.name = _name;
        this.activateID = _activateID;
        this.fa = _fa;
      }
      //Return an HTML element representation of the Menu
      GetHTMLElement = function () {
        var element = document.createElement("div");
        element.className = "menu  shadow";
        var p = document.createElement("p");

        var iT = document.createElement("i");
        iT.className = this.fa;
        p.appendChild(iT);

        p.innerHTML += " " + this.name;
        var i = document.createElement("i");
        i.className = "fa-solid fa-arrow-right";
        element.appendChild(p);
        element.appendChild(i);
        return element;
      };
    }

    //Define arrays for menus and pages
    var menus = [];
    var pages = [];

    //function for initializing, populating menu and page arrays
    function populateMenus() {



      menus.push(new Menu("Store specific help"));
      menus.push(new Menu("Modern Slavery Statement"));
      menus.push(new Menu("Help with Selling an item.."));
      menus.push(new Menu("Contact"));

      pages.push(document.getElementById("about"));
      pages.push(document.getElementById("OurCOVID-19Response"));
      pages.push(document.getElementById("ModernSlaveryStatement"));
      pages.push(document.getElementById("CounterfeitProductsPolicy"));



    }

    //Call functions and open first page
    populateMenus();
    displayUpdatedMenus();
    open(0);


    //update and render menus
    function displayUpdatedMenus() {
      var sidebarElement = document.getElementById("sidebar");
      var sidebarMenus = sidebarElement.querySelectorAll(":scope > .menu");
      for (let i = 0; i < sidebarMenus.length; i++) {
        sidebarMenus[i].remove();
      }
      for (let i = 0; i < menus.length; i++) {
        var element = menus[i].GetHTMLElement();
        sidebarElement.appendChild(element);
        element.addEventListener("click", function () {
          open(i);
        });
      }
    }


    //function for opening page on menu click
    function open(index) {
      for (let i = 0; i < pages.length; i++) {
        pages[i].style.display = "none";
      }
      pages[index].style.display = "block";

    }
  }, [])
  return (
    <>
      <Navbar /><br /><br /><br /><br />
      <section>

        <div class="allpage mb-5 ">

          <div id="sidebar" class="sidebar" >
            <h1 class="title " style={{ background: "#071A44", color: "white" }}>Support</h1>
          </div>








          {/* <!-- ----------------------ModernSlaveryStatement--------------------- --> */}
          <div id="ModernSlaveryStatement" class="page text-white" style={{ backgroundColor: "#071A44" }}>
            <h1>Modern Slavery Statement</h1>
            <h4>Introduction</h4>
            <p>This statement is made pursuant to section 54 of the Modern Slavery Act 2015. It constitutes QCL slavery and human trafficking statement for the financial year ended 31 December 2018.</p>
            <h4>Our Organization</h4>
            <p>QCL was founded in London in 1992. We have stores in the UK, Spain, Ireland, India, Australia, Portugal, Netherlands, Mexico, Poland and Canarias.</p>
            <p>We buy, sell and exchange a range of technology and entertainment products including mobile phones, video games, DVDs and Blu-ray movies, computers, digital electronics, TVs and monitors, and music CDs.</p>
            <p>We have more than 700 stores worldwide and we are growing.</p>
            <h4>
              Our Supply Chain</h4>
            <p>As a second hand trade company our supply chain is the customer. We do however work with third party companies who act as a support service to our needs.</p>
            <h4>Policies</h4>
            <p>https://dev-portal.cex.webuy.dev/transfer-group-managerAs an expanding business, we are in the process of reviewing our policies in order to make sure that our third parties understand our expectations with regards to human rights across all areas of our supply chain.</p>
            <p>QCL actively encourages employees to report any activity that they believe is in breach of company human rights standards, for this we have a whistleblowing procedure in place to report any concerns about the way we and our third parties perform our tasks.</p>
            <h4>Due Diligence and Risk Assessment</h4>
            <p>QCL is fortunate that out supply is from the general public, but we still use support services to assist with the day to day running of our business. Many of these parties already have robust Due Diligence measures in place.</p>
            <p>As part of the ongoing improvement to QCL policies and standards, we are ensuring that we have a more comprehensive approach to risk assessment and identification of risk. In 2018 we made substantial improvements to our compliance department to assist in reviewing policies and procedures belonging to ourselves and to our third parties.</p>
            <p>Where applicable we visit our third parties to ensure their working conditions, policies and procedures are up to an excellent standard. Given the geographical location of some of our third parties, we are committed to understanding our relationships better by ensuring transparency with strong working contracts.</p>
            <h4>Measurement of Effectiveness</h4>
            <p>CEX will continue to monitor our third party support services both existing and new. Should any instances of non-compliance with the Act arise in relation to any of our third parties then this will be reviewed.</p>

          </div>
          {/* <!-- ---------------------------------Store specific help-------------------------- --> */}
          <div id="OurCOVID-19Response" class="page text-white" style={{ backgroundColor: "#071A44" }}>
            <h1>Help with regards to our Stores</h1>
            <ul class="ml-3">
              <li>General Questions</li>
              <li>Product Questions</li>
              <li>Membership Requirements</li>
              <li>Miscellaneous Questions</li>
            </ul>
            <h3>General Questions</h3>
            <h5>How can I find my nearest store?</h5>
            <p>Head here and pop in your town or postcode into the search box under 'Find your nearest UK {Env.businessName} store'.</p>
            <h5>Where do I find the opening hours?</h5>
            <p>Opening hours can be found at the bottom of each dedicated store page.</p>
            <h5>Can I return from one store to another store?</h5>
            <p>Of course. All our stores accept returns from other {Env.businessName} stores.</p>
            <h5>How can I tell if the item I want is in my local {Env.businessName} shop?</h5>
            <p>Type the name of your product in the search bar and refine your search by selecting your local store using ‘A specific store’s stock’ drop down box, under "By Availability" on the right of the page.</p>
            <p>The goods I want are not in my local store, how can I get them?{Env.businessName} are online at webuy.com, so you can get what you want delivered right to your door.</p>
            <h5>Is the stock on the website accurate?</h5>
            <p>Yes it is! Pop down there before someone else gets their mitts on it.</p>
            <h5>How can I find out more about the goods I’m thinking of buying?</h5>
            <p>Check the product description of the item for more information.</p>
            <h5>What do I need to sell goods to a {Env.businessName}?</h5>
            <p>Become a member, it's free & easy! Check out our Membership Requirements here</p>
            <h5>Where can I find the phone number for my local {Env.businessName} store?</h5>
            <p>Unfortunately store phone numbers are for internal use only but please contact us with any questions you may have. If you are looking for live stock levels you can use our website.</p>
          </div>
          {/* <!-- ---------------------------------------elp with Selling an item to QCL------------------- --> */}
          <div id="CounterfeitProductsPolicy" class="page text-white" style={{backgroundColor: "#071A44"}}>
            <h1>Help with Selling an item to QCL</h1>
            <ul class="ml-3">
              <li>General</li>
              <li>Order tracking</li>
              <li>Overdue Delivery</li>
              <li>Returns policy</li>
              <li>Cancelling an Order</li>
              <li>Products</li>
            </ul>
            <h4>General</h4>
            <h6>Are your products new or second hand?</h6>
            <p>All products are second hand unless stated. Hello good Karma.</p>
            <h6>Is there a warranty on items that I buy from QCL?</h6>
            <p>Yes, for your peace of mind, {Env.businessName} offer a 24-month warranty* (on all items purchased on or after 14th Nov 2015) on all products excluding consumables.</p>
            <h6>Is there a limit to how much I can buy?</h6>
            <p>Yes, you can buy up to 2 of any 1 item, and your basket can have up to 50 items in total.</p>
            <h6>Are the quantities available to buy online live?</h6>
            <p>Yes, the stock and prices shown are live.</p>
          </div>
          {/* <!-- ---------------------------------Contact--------------------- --> */}
          <div id="about" class="page">
            <div class="container register-form  mr-3" style={{ marginTop: "-3rem" }}>
              <div class="form">
                <div class="form-content shadow">
                  <h1 className="text-center mt-3" style={{color: '#071A44'}}>Contact</h1>
                  <div class="row pt-4">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label style={{color: '#071A44'}} for="FirstName">Your Name</label>
                        <input type="text" class="form-control" placeholder="First name" value="" />
                      </div>
                      <div class="form-group">
                        <label for="EnquiryType" style={{color: '#071A44'}}>Type of enquiry</label>
                        <input type="text" class="form-control" placeholder="Email Address" value="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="YourEmail" style={{color: '#071A44'}}>Your e-mail address</label>
                        <input type="text" class="form-control" placeholder="Last name" value="" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="Subject" style={{color: '#071A44'}}>Subject</label>
                    <input type="text" class="form-control" placeholder="Subject" value="" />
                  </div>
                  <div class="form-group">
                    <label for="Message" style={{color: '#071A44'}}>Message</label>
                    <textarea class="form-control" rows="3"></textarea>
                  </div>
                  <hr />
                  <h4 class="font-weight-bold" style={{color: '#071A44'}}>Visit us</h4>
                  <p>If you want to speak to us in person, you can use our Store Finder and visit us in one of our stores.</p>
                  <p>If you wish to write to us, our postal address is below:</p>
                  <address>
                    6 HANNAM COURT<br />
                    CHARLES STREET<br />
                    LEICESTER<br />
                    ENGLAND<br />
                    LE1 3FS
                  </address>
                  <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-lg bg-white fw-bolder fs-9 custom-width p-2" style={{color: '#071A44', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>Send Your Message</button>
                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>

      </section>
      <Footer />
    </>
  )
}
export default Support;