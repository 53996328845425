import React from 'react'
import { getAsset } from '../utils/helper'
import { Link } from 'react-router-dom'
import "./LandingPage.css";


function MainModel({link,image,title,description}) {

    return (
        <div className="col-lg-5">
                <div
                    className="card bg-dark text-white"
                    style={{ position: "relative", height: "620px", marginTop: '15px' }}
                >
                    <img
                    className="card-img"
                    src={image && getAsset(image) ||"https://ik.imagekit.io/vhfsx9xkeh/oscar-nord-8l9VxXI28tY-unsplash.jpg?updatedAt=1710857569739"}
                    alt="Card image"
                    style={{ objectFit: "cover", height: "100%", opacity:"0.5" }}
                    />
                    <div
                    className="card-img-overlay"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: '40px'
                        // alignItems: 'center'
                    }}
                    >
                    <h1
                        className="card-text text-white "
                        style={{ marginTop: "20vh", fontWeight: "bold" }}
                    >
                        {title ||`Best  \n
                        Product  \n
                        Deals`}
                    </h1>
                    <p
                        className="card-text text-white "
                        style={{ margin: "0", marginTop: "10px" }}
                    >
                        {description ||`Get a 20% Cashback when\n
                        buying TWS Product from   \n
                        Fone Care  \n
                        Manchaters.   `}
                    </p>
                    <Link to={ link || '/AllTypes'}>
                        <button
                            className="align-start shopbutton text-black text-center p-2"
                            style={{
                                backgroundColor: "black",
                                width: window.innerWidth <= 550 ? "50%" : "20%",
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                        >
                            Buy Now
                        </button>
                    </Link>
                    </div>
                </div>
        </div>
    )
}

export default MainModel