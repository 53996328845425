import React, { useState } from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import "./category.css";




const CategorySlider = ({ NavbarData, getAsset, startIndex, maxIndexToShow }) => {
    const navigate = useNavigate();
    const itemsPerPage = 4;
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSelect = (selectedIndex) => {
        setCurrentSlide(selectedIndex);
    };

    const slides = [];
    for (let i = 0; i < NavbarData.length; i += itemsPerPage) {
        slides.push(NavbarData.slice(i, i + itemsPerPage));
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div class="container-fluid">
                <div class="row align-items-center justify-content-center ico-2">

                    {/* NAVIGATION ICONS TO SHOW ON SMALL DEVICES */}
                    <div class="sticky-sm-top d-md-none d-flex justify-content-end" style={{ position: "sticky", top: "0" }}>
                        <button style={{ background: "none", border: "none" }} onClick={() => handleSelect((currentSlide - 1 + slides.length) % slides.length)}>
                            <i class="fas fa-chevron-left ico" style={{ margin: "10px" }}></i>
                        </button>
                        <button style={{ background: "none", border: "none", float: "inline-end" }} onClick={() => handleSelect((currentSlide + 1) % slides.length)}>
                            <i class="fas fa-chevron-right" style={{ margin: "10px" }}></i>
                        </button>
                    </div>




                    {/* <!-- Adjusted for responsiveness --> */}
                    <div class="col-12 col-md-1 d-none d-md-block flex-column align-items-center">
                        <button style={{ background: "none", border: "none" }} onClick={() => handleSelect((currentSlide - 1 + slides.length) % slides.length)}>
                            <i class="fas fa-chevron-left ico" style={{ margin: "10px" }}></i>
                        </button>
                    </div>
                    <div class="col-12 col-md-10">
                        <Carousel
                            activeIndex={currentSlide}
                            onSelect={handleSelect}
                            indicators={false}
                            controls={false}
                            interval={null}
                            slide={true} // Enable sliding
                        >
                            {slides.map((slide, slideIndex) => (
                                <Carousel.Item key={slideIndex}>
                                    <div class="product-slider-container">
                                        <div class="product-slider-row">
                                            {slide.map((item, index) => (
                                                <div class="product-card col-lg-3 col-md-6 col-sm-12">
                                                    <div class="product-details">
                                                        <h3>{item.type}</h3>
                                                        <button style={{
                                                            backgroundColor: 'black',
                                                            padding:"6px 8px",
                                                            border: '1px solid black',
                                                            color: 'white',
                                                        }} onClick={() => navigate(`/AmPage3/${item.type}`)}>View Now</button>
                                                    </div>
                                                    <div className='catimage-div' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <img src={getAsset(item.image[0])} style={{ width: "100%", maxWidth: "60%", height: "auto" }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div class="col-12 col-md-1 d-none d-md-block flex-column align-items-center">
                        <button style={{ background: "none", border: "none", float: "inline-end" }} onClick={() => handleSelect((currentSlide + 1) % slides.length)}>
                            <i class="fas fa-chevron-right" style={{ margin: "10px" }}></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategorySlider;

