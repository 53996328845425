import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Env from '../Environment/Env';
function PrivacyPolicy() {
  return (
    <>
    <Navbar />
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col">
          <h2>Privacy Policy of {Env.businessName}</h2>
          <p>At {Env.businessName}, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By accessing or using the {Env.businessName} website, you consent to the terms of this Privacy Policy.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Information We Collect</h3>
          <p>Personal Information: When you visit our website or use our services, we may collect personal information such as your name, email address, phone number, shipping address, and payment details.</p>
          <p>Usage Information: We may also collect non-personal information about your interaction with our website, including your IP address, browser type, device information, and browsing patterns.</p>
          <p>Cookies:{Env.businessName} uses cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and personalize content. You can manage your cookie preferences through your browser settings.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>How We Use Your Information</h3>
          <p>To Process Orders: We use the information you provide to process and fulfill your orders, including shipping products to your designated address and processing payment transactions.</p>
          <p>Customer Support: We may use your information to respond to your inquiries, provide customer support, and address any issues or concerns you may have.</p>
          <p>Marketing Communications: With your consent, we may send you promotional emails or marketing communications about our products, services, and special offers. You can opt out of receiving these communications at any time.</p>
          <p>Improving Services: We use data analytics to analyze user behavior and website performance, which helps us improve our services, optimize the user experience, and develop new features.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Information Sharing and Disclosure</h3>
          <p>Third-Party Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website, conducting business activities, or providing services to you.</p>
          <p>Legal Compliance: We may disclose your information when required by law, court order, or other legal process, or to protect our rights, property, or safety, or the rights, property, or safety of others.</p>
          <p>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you of any such transfer and any changes to our Privacy Policy.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Data Security</h3>
          <p>{Env.businessName} employs industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
          <p>It is important to keep your account login credentials confidential and to log out of your account after each session to prevent unauthorized access.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Children's Privacy</h3>
          <p>{Env.businessName} does not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will promptly delete such information from our records.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Your Privacy Choices</h3>
          <p>Opt-Out: You have the right to opt out of receiving marketing communications from {Env.businessName}. You can unsubscribe from promotional emails by following the instructions provided in the email or by contacting us directly.</p>
          <p>Access and Correction: You may access, update, or correct your personal information by logging into your account on the {Env.businessName} website or by contacting us.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Changes to Privacy Policy</h3>
          <p>{Env.businessName} reserves the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the updated Privacy Policy on the website. We encourage you to review this Privacy Policy periodically for any updates or changes.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Contact Us</h3>
          <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at info@{Env.businessName}.co.uk.</p>
          <p>By using the {Env.businessName} website and services, you acknowledge that you have read, understood, and agreed to this Privacy Policy. If you do not agree with any aspect of this Privacy Policy, please refrain from using our website and services.</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default PrivacyPolicy;
