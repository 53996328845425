import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Env from '../Environment/Env';
function CookiesPolicy() {
  return (
    <>
    <Navbar />
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col">
          <h2>Cookies Policy and GDPR Consent</h2>
          <p>This Cookies Policy and GDPR Consent outlines how {Env.businessName} uses cookies and similar tracking technologies on our website, as well as your rights regarding the collection and processing of your personal data in compliance with the General Data Protection Regulation (GDPR).</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>What Are Cookies?</h3>
          <p>Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website. They are widely used to enhance your browsing experience, personalize content, analyze website traffic, and track user behavior.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Types of Cookies We Use</h3>
          <p>Essential Cookies: These cookies are necessary for the functioning of our website and enable core functionalities such as accessing secure areas and processing orders.</p>
          <p>Analytical/Performance Cookies: These cookies allow us to analyze website traffic, track user interactions, and improve website performance.</p>
          <p>Functional Cookies: These cookies enhance the user experience by remembering your preferences and settings.</p>
          <p>Targeting/Advertising Cookies: These cookies are used to deliver targeted advertisements based on your browsing habits and interests.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>How We Use Cookies</h3>
          <p>Personalization: We use cookies to personalize your browsing experience and display relevant content based on your preferences.</p>
          <p>Analytics: We use cookies to gather information about how visitors interact with our website, analyze website traffic patterns, and improve our website's performance.</p>
          <p>Advertising: We may use cookies to deliver targeted advertisements to you based on your browsing behavior and interests.</p>
          <p>Third-Party Cookies: Some cookies on our website may be placed by third-party service providers, such as advertising networks or social media platforms. These third parties may use cookies to collect information about your online activities across different websites.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Your Cookie Preferences</h3>
          <p>Cookie Consent: By using our website, you consent to the use of cookies as described in this Cookies Policy. You may withdraw your consent or manage your cookie preferences at any time through your browser settings.</p>
          <p>Cookie Settings: Most web browsers allow you to control cookies through their settings. You can typically configure your browser to accept, reject, or delete cookies, as well as to notify you when a cookie is being sent.</p>
          <p>Opting Out: You can opt out of targeted advertising cookies by visiting the opt-out pages provided by advertising networks or through the settings of your mobile device.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>GDPR Consent</h3>
          <p>Data Collection: By using our website, you consent to the collection and processing of your personal data as described in our Privacy Policy.</p>
          <p>Data Processing: We process personal data for specified purposes and with appropriate legal grounds as outlined in our Privacy Policy.</p>
          <p>Data Protection Rights: Under the GDPR, you have certain rights regarding your personal data, including the right to access, rectify, or delete your data, the right to object to processing, and the right to data portability.</p>
          <p>Consent Withdrawal: You may withdraw your consent to the processing of your personal data at any time by contacting us or adjusting your cookie settings.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Contact Us</h3>
          <p>If you have any questions, concerns, or requests regarding our Cookies Policy, GDPR consent, or the handling of your personal data, please contact us at info@{Env.businessName}.co.uk.</p>
          <p>By continuing to use our website and services, you acknowledge that you have read, understood, and agreed to this Cookies Policy and GDPR Consent. If you do not agree with any aspect of this policy, please refrain from using our website and services.</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default CookiesPolicy;
