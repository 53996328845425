import React,{useState,useEffect} from 'react'
import { getAsset } from '../utils/helper'
import star from "./star.png";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import "./dynamic.css";


function TimeCard({ title, oldPrice, newPrice, endDate, image }) {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const calculateTimeLeft = () => {
        const difference = new Date(endDate) - new Date();
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [endDate]);

    const isSaleEnded = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;

    return (
        <Col
            md={5}
            style={{
                border: '1px solid lightgray',
                padding: '15px',
                borderRadius: '12px',
            }}
        >
            <Link to="/AllTypes">
                <Row>
                    <Col className="picx">
                        <img
                            src={getAsset(image) || 'https://ik.imagekit.io/cy8phtesy/Samsung_S8_Unlocked_64GB_4wMubRwYg.png?updatedAt=1715692020066'}
                            alt="product"
                            width="80%"
                            height="auto"
                        />
                    </Col>
                    <Col className="nin">
                        <h5 style={{ fontWeight: 'bold' }}>{title || 'Samsung S12'}</h5>
                        <p style={{ color: '#FF0000' }}>
                            £{newPrice || 300}
                            <span style={{ color: '#B0B0B0', textDecoration: 'line-through' }}>
                                {'  £'}{oldPrice || 320}
                            </span>
                        </p>
                        <div
                            style={{
                                color: 'yellow',
                                fontSize: '12px',
                                gap: '2px',
                                marginBottom: '5px',
                                display: 'flex',
                            }}
                        >
                            <img src={star} alt="star" width="15px" height="auto" />
                            <img src={star} alt="star" width="15px" height="auto" />
                            <img src={star} alt="star" width="15px" height="auto" />
                            <img src={star} alt="star" width="15px" height="auto" />
                            <img src={star} alt="star" width="15px" height="auto" />
                        </div>
                        {isSaleEnded ? (
                            <div style={{ textAlign: 'center', color: '#FF0000',backgroundColor:'#770000', fontWeight: 'bold',padding:'1rem 2rem',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                <span style={{fontSize:'22px'}}>Sale Ended</span>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'start',textAlign: 'center' }}>
                                <div style={{ backgroundColor: '#999999', maxWidth: '50px', padding: '5px', borderRadius: '5px' }}>
                                    <h5 style={{ color: '#FBBC04', textAlign: 'center', paddingTop: '5px' }}>{timeLeft.days}</h5>
                                    <hr style={{ color: 'white' }} />
                                    <p style={{ color: '#FBBC04', textAlign: 'center' }}>Days</p>
                                </div>
                                <div style={{ backgroundColor: '#999999', maxWidth: '50px', padding: '5px', borderRadius: '5px' }}>
                                    <h5 style={{ color: '#FBBC04', textAlign: 'center', paddingTop: '5px' }}>{timeLeft.hours}</h5>
                                    <hr style={{ color: 'white' }} />
                                    <p style={{ color: '#FBBC04', textAlign: 'center' }}>Hours</p>
                                </div>
                                <div style={{ backgroundColor: '#999999', maxWidth: '50px', padding: '5px', borderRadius: '5px' }}>
                                    <h5 style={{ color: '#FBBC04', textAlign: 'center', paddingTop: '5px' }}>{timeLeft.minutes}</h5>
                                    <hr style={{ color: 'white' }} />
                                    <p style={{ color: '#FBBC04', textAlign: 'center' }}>Mins</p>
                                </div>
                                <div style={{ backgroundColor: '#999999', maxWidth: '50px', padding: '5px', borderRadius: '5px' }}>
                                    <h5 style={{ color: '#FBBC04', textAlign: 'center', paddingTop: '5px' }}>{timeLeft.seconds}</h5>
                                    <hr style={{ color: 'white' }} />
                                    <p style={{ color: '#FBBC04', textAlign: 'center' }}>Secs</p>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Link>
        </Col>
    );
}


function LimitedTimeOffers({ltoData}) {
    return (
        <Container style={{ margin: window.innerWidth <= 550 ? ".1rem" : "3rem"
        }}>
                <Row className="lim-cards gap-5 align-items-center">
                    {ltoData.map((item, index) => (
                        <TimeCard
                            key={index}
                            title={item.title}
                            oldPrice={item.oldPrice}
                            newPrice={item.newPrice}
                            endDate={item.endDate}
                            image={item.image}
                        />
                    ))}
                </Row>
            </Container>
    );
}

export default LimitedTimeOffers;
