const Env = {
  // server: "http://localhost:8089",
  // dashboard: "http://localhost:3001/",  
  server: "https://accback.selectezi.com",
  dashboard: "https://accdash.selectezi.com/",  
  tradeSite: "https://acctrade.selectezi.com",  
  picLogo: "https://ik.imagekit.io/cy8phtesy/logo.png?updatedAt=1714802468695",
  address: "Mobilebitz Headoffice , 8 Bridge Business Centre , Bridge Road , Southall , UB2 4AY,UK",
  phone: "0208 8139930",
  email: "admin@mobilebitz.co.uk",
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",
  twitter: "https://twitter.com/",
  linkedin: "https://www.linkedin.com/",
  businessName:'MobileBitz',
  publicKey:'pk_live_51NafYiC8l35AdS8bskoI1jntK609FzUuISDxyJbepx0gzuM4ZQUwuS4eWtxBKh0OqIjv2tsGlsJiXn2Ny5eEcW9a00O2C48djZ'
}
export default Env;
