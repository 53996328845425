import React, { useEffect, useState } from "react";
// import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper"; 
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Paper, Grid, Card, CardContent} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer/Footer";
import Loader from './../loader.gif';

const AccessoryType = () => {
  
  const navigate = useNavigate();
  const {model , type}= useParams();

  const Model = decodeURIComponent(model);
  
  const [options, setOptions] = useState(['Choose Options']);

  console.log(type , model);

  useEffect(()=>{
    console.log(model, type); 
    getAllByNmeAcc();
  }, [model,type]);


  const [isSelected, setIsSelected] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [ModelGetByParams, setModelGetByParams] = useState([]);
  const [AccessGetByParams, setAccessGetByParams] = useState([]);
  const [allProductData1, setAllProductData1] = useState([]);
  const [allProductData, setAllProductData] = useState([]); 
  const [FilteredData, setFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [UniqueTypes, setUniqueTypes] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const [data, setdata] = useState();
  const [anchorEl, setAnchorEl] = useState(null);


  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        let resp = res.data.subsubcatagories;
        // Extracting unique types
        const types = Array.from(new Set(resp.map(item => item.typeName)));
        console.log("types====", types)
        setUniqueTypes(types);
      })
      .catch((err) => {
        console.log("err=====", err);
      })
  }, [])

  const handleCheckboxChange = () => {
    setIsSelected(!isSelected); 
};

  const [MinRange, setMinRange] = useState( 0);
  const [MaxRange, setMaxRange] = useState(6000);
  
  const [selectedRange, setSelectedRange] = useState( 6000);



  const handleRangeChange = (event) => {
      const range = parseInt(event?.target?.value);
      localStorage.setItem("priceFilter", range);
      setSelectedRange(range);
  };

  useEffect(()=>{
    applyFilterProper();
  },[selectedRange,MinRange,selectedFilters]);

  const applyFilterProper = async() =>{
    setIsLoading(true);
    let url = `${Env.server}/api/subsubcatagory/GetByFilterForRetailByModel?min=${MinRange}&max=${selectedRange}&subcatagory=${encodeURIComponent(model)}`;
    if(selectedFilters.length === 0 && selectedRange === MaxRange){
        getAllByNmeAcc();
        return;
    }
    console.log('aaaaaaaaaaaaaaaaaaaa',selectedFilters);
    selectedFilters.map(filter=>{
        if(filter.type === "Manufacturer"){
            url+= `&manufacturerName=${filter.var}`
        }
        if(filter.type === "Color"){
            url+= `&color=${filter.var}`
        }
        if(filter.type === "Variant"){
            url+= `&variantName=${filter.var}`
        }
    })
    await axios.get(url)
    .then((res)=>{
        console.log('aaaaaaaaaaaaaaaaaaa',res.data.data);
        setAllProductData(res.data.data);
    })
    .catch((err)=>console.log('aaaaaaaaaaaaaaaaaaaa',err));
    setIsLoading(false);
  }

  const handleFilter=()=>{
    // localStorage.setItem("priceFilter", MaxRange); 
    console.log("abc===", allProductData1)
    let filteredItems = allProductData1 && allProductData1.filter(item => parseInt(item.productPrice) <= MaxRange && parseInt(item.productPrice) >= MinRange );
    setAllProductData(filteredItems)
    // window.location.reload();
  }


  function groupProductsByType(products) {
    const groupedProducts = {};
    products.forEach(product => {
      if (!groupedProducts[product.typeName]) {
        groupedProducts[product.typeName] = [];
      }
      groupedProducts[product.typeName].push(product);
    });
    return groupedProducts;
  }


  const getAllByNmeAcc = async() => {
    setIsLoading(true);
    console.log('fffffff');
    console.log("abc---", encodeURIComponent(Model))
    await axios.get(`${Env.server}/api/subsubcatagory/getAllByTypeByModel/${type}/${encodeURIComponent(Model)}`)  
      .then((res) => {
        console.log("abcccc====>>>", res.data.subsubcatagories);
        setModelGetByParams(res.data.subsubcatagories);
        setAccessGetByParams(res.data.subsubcatagories)
        setdata(res.data.subsubcatagories)
        console.log("abcccc====>>>", res.data.subsubcatagories);  
        const prodArr = res.data.subsubcatagories;
                console.log('fffff',prodArr);
                const maxNum = Math.max(...prodArr.map(product => product.productPrice));
                const minNum = Math.min(...prodArr.map(product => product.productPrice));
                if(minNum === maxNum){
                  setMinRange(0);
                }else{
                  setMinRange(minNum === Infinity ? 0 : minNum);
                }
                setMaxRange(maxNum === -Infinity ? 0 : maxNum);
                if(!localStorage.getItem("minRange")){
                    console.log('I just set the value to be',MinRange);
                }else{
                    setMinRange(localStorage.getItem("minRange"));
                }
                setSelectedRange(maxNum === -Infinity ? 0 : maxNum);
                

                if (localStorage.getItem("filterselected") === 0) {
                    console.log("");
                } else {
                    const savedFilters = localStorage.getItem('selectedFilters');
                    if (savedFilters) {
                        setSelectedFilters(JSON.parse(savedFilters));
                    }
                }

                console.log("res.data.subsubcatagories====", res.data.subsubcatagories)
                setAllProductData(res.data.subsubcatagories)
                setAllProductData1(res.data.subsubcatagories)
                let dataAll = res.data.subsubcatagories

                console.log('Selected Range ==>',selectedRange);
                let filteredItems = dataAll && dataAll.filter(item => parseInt(item.productPrice) <= selectedRange && parseInt(item.productPrice) >= MinRange);
                console.log("muzzamal====", filteredItems)
                setAllProductData(filteredItems)
                setAllProductData1(filteredItems)
        // setAllProductData(res.data.subsubcatagories)
        // setAllProductData1(res.data.subsubcatagories)

        let data = res.data.subsubcatagories;
        // console.log("hhhhhhhhhhhhhhhh======", data);
        const combinedFilters = {};

        data.forEach((product) => {
          if (product.filter) {
            product.filter.forEach((filterCategory) => {
              Object.entries(filterCategory).forEach(([key, values]) => {
                if (values.length === 0) return; // Skip if no data

                if (!combinedFilters[key]) {
                  combinedFilters[key] = [];
                }
                values.forEach((value) => {
                  let existingFilter = combinedFilters[key].find(
                    (existing) => existing.var === value
                  );
                  if (!existingFilter) {
                    combinedFilters[key].push({ var: value, id: [product._id] });
                  } else {
                    existingFilter.id.push(product._id);
                  }
                });
              });
            });
          }
        });

        console.log("Combined filters:", combinedFilters);
        setFilteredData(combinedFilters);

        const subCatagoryArray = res.data.subsubcatagories.map(data => data.subcatagory);
                setOptions(prevOptions => {
                    const mergedOptions = [...prevOptions, ...subCatagoryArray];
                    const uniqueOptions = Array.from(new Set(mergedOptions));
                    return uniqueOptions;
                });

                // handleFiltersSelected();

      })
      .catch((err) => {
        console.log("err====", err)
      })
      setIsLoading(false);
  }

    const uniqueSubcategories = new Set(ModelGetByParams.map(card => card.subcatagory));

    // Convert Set back to an array
    const uniqueSubcategoriesArray = [...uniqueSubcategories];


  const handleCardClick = (selectedProduct, quantity) => {
    if(quantity === undefined){
      quantity =1;
    }
    console.log("quantity------", quantity);
    let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    // Check if the selected product already exists in the array
    const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

    if (existingProductIndex !== -1) {
      // If the product already exists, update it including quantity
      products[existingProductIndex].quantity = quantity;
    } else {
      // If the product does not exist, add it to the array including quantity
      selectedProduct.quantity = quantity;
      products.push(selectedProduct);
    }

    // Store the updated products array in local storage
    localStorage.setItem('selectedProducts', JSON.stringify(products));
  }

  





  useEffect(() => {
    // Retrieve saved selected filters from localStorage when the component mounts
    const savedFilters = localStorage.getItem('selectedFilters');
    if (savedFilters) {
      setSelectedFilters(JSON.parse(savedFilters));
    }
  }, []);


  // useEffect(()=>{
  //   handleFiltersSelected();
  // },[selectedFilters])

const handleFiltersSelected = ()=>{
    console.log('here is the data of need ===>>',selectedFilters);
    if(selectedFilters.length > 0){
    let outputArray = [];
        selectedFilters.forEach((filter) => {
            const filterid = filter.id[0];
            outputArray = [...outputArray,...allProductData.filter(prod=>filterid.includes(prod._id))];
        });
        // console.log('Here is data of neeed ====>>>',outputArray);
        setAllProductData(outputArray);
    }
}

useEffect(()=>{
    localStorage.setItem('selectedFilters',[]);
},[model,type]);


const handleCheckboxClick = (filterVar, productId,filterName) => {
  setSelectedFilters(prevFilters => {
      const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);
      
      if (selectedIndex === -1) {
          const newFilters = [...prevFilters, { var: filterVar, id: [productId],type:filterName }];
          console.log('Here is the data vvv=>>',newFilters);
          return newFilters;
      } else {
          const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
          return newFilters;
      }
  });
};



  useEffect(() => {
    // Log the IDs of all selected filters after each state update
    const selectedIds = selectedFilters.flatMap(filter => filter.id);
    console.log("Selected IDs:", selectedIds);
  }, [selectedFilters]);



  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
};

const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
};


  useEffect(()=>{
    console.log('This is data ====>>>',allProductData);
  },[])

  const [productQuantities, setProductQuantities] = useState({}); 
  const increaseQuantity = (productId) => {
    setProductQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 0) + 1
    }));
  };

  // Function to handle decreasing the quantity for a specific product
  const decreaseQuantity = (productId) => {
    if (productQuantities[productId] && productQuantities[productId] > 0) {
      setProductQuantities(prevQuantities => ({
        ...prevQuantities,
        [productId]: prevQuantities[productId] - 1
      }));
    }
  };

  // Function to handle changing the quantity directly for a specific product
  const handleQuantityChange = (productId, event) => {
    const value = parseInt(event?.target?.value);
    if (!isNaN(value) && value >= 1) {
      setProductQuantities(prevQuantities => ({
        ...prevQuantities,
        [productId]: value
      }));
    }
  };







    return (
        <>
        
            <Navbar />      
        



       <br/>


     


        <div className="container-fluid">
          <div className="row"> 

            <div className="col-lg-3 col-md-2 pt-4 mb-3" style={{}}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                    <input
                        type="range"
                        min={MinRange}
                        max={MaxRange + 1}
                        step="1"
                        value={selectedRange}
                        onChange={handleRangeChange}
                    />
                    </div>
                    <div style={{  justifyContent: 'space-between', marginTop: '10px' }}>
                        <input type="text" style={{ width: '35%' }} value={MinRange} onChange={(e)=> {
                            setMinRange(e.target.value)
                            localStorage.setItem("minRange",e.target.value);
                            }} placeholder="" /> - to -
                        <input type="text" style={{ width: '35%' }} value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} placeholder="" />
                        <button onClick={handleFilter} style={{ width: '50%' , borderRadius:"17px" , border:"1px solid transparent", backgroundColor: '#DB241B', color: 'white', padding: '5px', marginTop: '8px' }} >Filter</button>
                    </div>
                </div>

                        <div>
                            {/* Map over the filtered data and create checkboxes */}
                            {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                                // Check if filterName is not "Variant"
                              filterName !== "ProductPrice" &&
                                <div className="box w-75 my-4">
                                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                                    <div className="p-2">
                                        {/* Map over filter values and create checkboxes */}
                                        {filterValues.map(filter => (
                                            <div key={filter.var} className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={filter.var}
                                                    checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                                                    onClick={() => handleCheckboxClick(filter.var, filter.id, filterName)}
                                                />
                                                <label className="form-check-label" style={{textWrap: 'nowrap'}} htmlFor={filter.var}>
                                                    {filter.var}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
              
              
              {/* <div>
                {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                  <div className="box w-75 my-4">
                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                    <div className="p-2">                
                      {filterValues.map(filter => (
                        <div key={filter.var} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={filter.var}
                            checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                            onClick={() => handleCheckboxClick(filter.var, filter.id)}
                          />
                          <label className="form-check-label" htmlFor={filter.var}>
                            {filter.var}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>  */}
              {/* <p style={{ fontWeight: 'bold' }}>Browse by Category</p>
              <ul style={{ listStyleType: 'none' }}>
                {UniqueTypes && UniqueTypes.map((data, index) => (
                  <>
                    <Link to={`/AmPage3/${data}`}>  <li>{data}</li></Link>
                  </>
                ))}

              </ul> */}
            </div>


            <div className="col-lg-9">
              <div className="container">
              {
                isLoading ? 
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'70vh'}} >
                      <img src={Loader} alt="Loading..." width={120} height={120} />
                  </div>:
                  <>
                {allProductData && (
                  Object.entries(groupProductsByType(allProductData)).map(([type, products]) => (
                    <div key={type}>
                      <h3 className="" style={{fontFamily: "poppins", fontWeight: "bold"}}>{type}</h3>
                      <div className="row">
                        {products.map((card, index) => (
                          <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <Link to={`/Checkout/${card._id}`}>
                            <div className="card mb-4 align-items-center pt-4 pr-2 pl-2" style={{ cursor: "pointer", backgroundColor: "white"}}>
                              <div className="view overlay">
                                <img className="card-img-top" style={{ height: "110px", width: "110px", border: "none" }} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                <a href="#!">
                                  <div className="mask rgba-white-slight"></div>
                                </a>
                              </div> 
                              {/* <div className="row align-items-center pt-2">
                                <h5 className="card-title text-center">{card.subsubcatagory}</h5>
                                <h5 className="card-title text-center">£{card.productPrice}</h5>
                              </div>  */}
                              <div className="row align-items-center pt-2">
                                {card.subsubcatagory.split(" ").length > 8 ? (
                                    <h5 style={{fontSize: '13px', lineHeight:"18px"}} className="card-text text-left">
                                      {card.subsubcatagory.split(" ").slice(0, 8).join(" ")}...
                                    </h5>
                                  ) : (
                                    <h5 style={{fontSize: '13px', lineHeight:"18px"}} className="card-text text-left">
                                      {card.subsubcatagory}
                                    </h5>
                                  )}
 {card.subcatagory && <div>{card.subcatagory}</div>}
<div style={{ display: 'flex', alignItems: 'center', margin: "4px 0px" }}>
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
        </div>

                                  <h5 style={{fontSize: '13px'}} className="card-title text-left">£{card.productPrice}</h5>
                              
                              
                                  <div style={{ display: 'flex' }}>
                                    <img src="https://ik.imagekit.io/vhfsx9xkeh/Ok.png?updatedAt=1712168587135" width="8%" height="8%" />
                                    <h5 style={{ fontSize: '13px', lineHeight: "18px", marginLeft: '5px' }} className="card-text text-left">
                                      {card.qty === 0 ? <span style={{ fontSize: '13px', color: "red" }} className="card-text text-left">Out of Stock</span> : <span style={{ fontSize: '13px', color: "green" }} className="card-text text-left">In Stock</span>}

                                    </h5>
                                    </div>

                              </div>

                              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                
                              </div> 

                              {/* <div className="col-md-12 mt-3">
                              <div className="row align-items-center">
                                <div className="col-md-12" >

                                  
                                
                                <button className="btn" style={{ backgroundColor: '#DB241B', color: "white", cursor: "pointer", width: "100%" }} onClick={() => {
                                    
                                    navigate(`/Checkout/${card._id}`);
                                  }}>Buy</button>


                                </div> 
                                
                                
                                <br />
                              </div>
                              </div> */}
                              <br />
                            </div>
                            </Link>
                          </div>
                        ))}
                    
                      </div>
                      

                    
                      <div style={{ width: '100%'}}><hr /></div>
                    </div>
                  ))
                )}
                </>
                }
              </div>
            </div>




          </div>
        </div>           
        <Footer />
        </>
    )
}
export default AccessoryType; 