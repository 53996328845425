import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import axios from 'axios';
import Env from '../Environment/Env';

const Sidebar = ({ showSidebar, toggleSidebar }) => {
    const [activeDropdowns, setActiveDropdowns] = useState([]);
    const [NavbarData,setNavbarData] = useState([]);

    const getAllNavbarData=()=>{
        axios.get(`${Env.server}/api/filters/gettypePermitedToShow`)
        .then((res) => {
            console.log("===================", res.data.allType)
            setNavbarData(res.data.allType)
        })
        .catch((err) => {
            console.log("err====", err)
        })
    }

    useEffect(()=>{
        getAllNavbarData();
    },[])

    const handleClickOutside = (event) => {
        if (!showSidebar || event.target.closest('.sidebar1')) return;
        toggleSidebar();
    };

    const toggleDropdown = (index) => {
        const newActiveDropdowns = [...activeDropdowns];
        if (newActiveDropdowns.includes(index)) {
            newActiveDropdowns.splice(newActiveDropdowns.indexOf(index), 1);
        } else {
            newActiveDropdowns.push(index);
        }
        setActiveDropdowns(newActiveDropdowns);
    };

    return (
        <>
            <div className={`sidebar1 ${showSidebar ? 'active' : ''}`} onClick={handleClickOutside}>
                <div className="sidebar-header1">
                    <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={toggleSidebar} />
                </div>
                <ul className="sidebar-menu1">
                    {
                        NavbarData && Array.isArray(NavbarData) && NavbarData.map(
                            (item,index) =>(
                                <div key={item._id}>
                                    <div
                                        className="dropdown-container"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => toggleDropdown(index)}
                                    >
                                        <Link to={ `AmPage3/${item.type}`} style={{padding:'5px 12px',borderBottom:'1px solid #eee',display:'block',width:'100%'}} >
                                            {item.type}
                                        </Link>
                                    </div>
                                </div>
                            )
                        )
                    }
                </ul>
            </div>
        </>
    );
};

export default Sidebar;
