
import './App.css';
import React,{useState , useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Homepage/Homepage';
import Navbar from './Navbar/Navbar'; 
import Navbar1 from './Navbar/Navbar1'; 
import TradeSignup from './Navbar/TradeSignup/TradeSignup';
import TradeSignin from './Navbar/TradeSignup/TradeSignin'; 
// import Banar1 from './Banar/Banar1';
import Brands from './Banar/Brands';
import Model from './ModelPage/Model';
import BrandPage from './BrandPage/BrandPage';
import AwmPage from './AwmPage/AwmPage';
import AmPage from './AmPage/AmPage';
import Footer from './Footer/Footer';
import Checkout from './Checkout/Checkout';
import Cart1 from './Checkout/Cart1';
import Cart2 from './Checkout/Cart2';
import Cart3 from './Checkout/Cart3';
import Singin from './formlogin&singup/singin';
import Singup from './formlogin&singup/singup';
import Slider from './Slider/Slider';
import Specification from './Specification/Specification';
import DashboardPurchases from './dashboardpages/DashboardPurchases';

import Slidercontent from './Slidercontent/Slidercontent';
import SlidercontentnNVADI from './Slidercontent/SidercontentnNVADI';
// import Aboutus from './Aboutus/Aboutus';
import Terms from './Footer/Terms'
import Aboutus from './Footer/Aboutus'
import Privacy from './Footer/Privacy'
import Faqs from './Footer/Faqs'
import Cookies from './Footer/Cookies'
import Support from './Support/Supt';
import 'swiper/swiper-bundle.css';

// import Selectedfield from './Specification/Selectedfield';
import Order from './Ordersummary/Order';
import Filter from './Fiterpage/Fiter';
import UserDashboad from './Dashboard/UserDashboard';
import BillingAddress from './Dashboard/BillingAddress';
import CancelOrders from './Dashboard/CancelOrders';
import Wishlist from './Dashboard/Wishlist';
import YourOrders from './Dashboard/YourOrders';
import Verify from './Ordersummary/Verify';
import ForgotPassword from './formlogin&singup/ForgotPassword';
import AmPage2 from './AmPage/AmPage2';
import AmPage3 from './AmPage/AmPage3';
import AmPage4 from './AmPage/AmPage4';
import Model2 from './ModelPage/ModelPage2';
import AccessoryType from './ModelPage/AccessoriesType';
import OrderComplete from './OrderComplete/OrderComplete';
import Contactus from "./Navbar/Contactus"
import AllTypes from "./LandingPage/AllTypes"
import InActiveUserDD from './Dashboard/InActiveUserDD';
import Env from './Environment/Env';


const App=()=> { 
  
  useEffect(()=>{
    document.title = Env.businessName;
  },[])


  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/AwmPageA/:brand' element={<AmPage2 />} />    {/*  top navbar search field */}
          <Route path='/AwmPageB/:Accessory' element={<AwmPage />} /> {/*  Landing page , Search bar Accessories  */}



          <Route path='/Model/:brand/:model' element={<Model/>}/>
          <Route path='/Models/:brand/:model' element={<Model2/>}/>
          <Route path='/AccessoryType/:model/:type' element={<AccessoryType/>}/>
          
          
          
          <Route path="Navbar" element={<Navbar />} />
          <Route path="Navbar1" element={<Navbar1 />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="TradeSignup" element={<TradeSignup />} />
          <Route path="TradeSignin" element={<TradeSignin />} />

          {/* <Route path="Navbar" element={<Navbar />} />   */}
          <Route path='brand' element={<Brands/>}/>



          <Route path='/BrandPage/:brand' element={<BrandPage/>}/>
          <Route path='/AmPage/:Model/:Accessory' element={<AmPage />} />     s
          <Route path='/AmPage2' element={<AmPage2 />} />
          <Route path='/AmPage3/:type' element={<AmPage3 />} />
          <Route path='/AmPage4/:type/:brand' element={<AmPage4 />} />
          <Route path="Checkout/:id" element={<Checkout />} />





          <Route path="/Cart" element={<Order />} /> 
          <Route path="/Cart1" element={<Cart1 />} />
          <Route path="/Cart2" element={<Cart2 />} />
          <Route path="/Cart3" element={<Cart3 />} />





          <Route path="Footer" element={<Footer />} />

          <Route path="Singin" element={<Singin />} />
          <Route path="Singup" element={<Singup />} />
          <Route path="AllTypes" element={<AllTypes />} />



          <Route path="/UserDashboard" element={<UserDashboad />} />
          <Route path="/UnVarifiedDD" element={<InActiveUserDD />} />
          <Route path="/BillingAddress" element={<BillingAddress />} />
          <Route path="/CancelOrders" element={<CancelOrders />} />
          <Route path="/YourOrders" element={<YourOrders />} />
          <Route path="/Wishlist" element={<Wishlist />} />
          <Route path="/dashboardPurchases" element={<DashboardPurchases />} />
 
          <Route path="Slider" element={<Slider />} />
          <Route path="Specification/:subcatagory/:subcatagoryid" element={<Specification />} />
          <Route path="Filter/:SCatagory" element={<Filter />} />
          {/* <Route path="Slidercontent" element={<Slidercontent />} /> */}
          <Route path="Slidercontent/:id" element={<SlidercontentnNVADI />} />
          <Route path="Aboutus" element={<Aboutus />} />
          <Route path="Cookies" element={<Cookies />} />
          <Route path="Privacy" element={<Privacy />} />
          <Route path="Terms" element={<Terms />} />
          <Route path="Faqs" element={<Faqs />} />

          <Route path="/OrderComplete/:id" element={<OrderComplete />} />  

          <Route path="Support" element={<Support />} />
          <Route path="verified/:id" element={<Verify />} />
          <Route path="forgotPassword/:id" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
