import React, { useEffect, useState } from 'react'
import { getAsset } from '../utils/helper'
import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Env from '../Environment/Env';



function HotSale() {
    const [image,setImage] = useState(null);
    const [link,setLink] = useState(null);
    const [title,setTitle] = useState(null);
    const [description,setDescription] = useState(null);
    const [tagLine,setTagLine] = useState(null);

    const fetchHotSellingActive = async() =>{
        axios.get(`${Env.server}/api/hotselling/getActive`)
        .then(res=>{
            setImage(res.data.image);
            setLink(res.data.link);
            setTitle(res.data.title);
            setDescription(res.data.description);
            setTagLine(res.data.tagLine);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        fetchHotSellingActive();
    },[])

    return (
        <div style={{backgroundColor: "#F7DB03", margin: window.innerWidth <= 750 ? "0rem 0rem" : " " }}>
            <Container style={{ padding: "1rem", }}>
                <Row className="align-items-center justify-content-center gap-5 ">
                    <Col md={4}>
                        <img
                            src={image ? getAsset(image) : "https://ik.imagekit.io/cy8phtesy/Samsung_S22_Unlocked_128_GB_dMAatsMh7y.png?updatedAt=1715691822613"}
                            alt="product"
                            width="100%"
                            height="auto"
                        />
                    </Col>
                    <Col md={4}>
                        <div className='minim'>
                            <p>{tagLine || 'Redefining Excellence'}</p>
                            <h4 style={{ fontWeight: "bold" }}>{ title || 'Samsung Ultra Pro Max'}</h4>
                            <p style={{fontSize:"1rem"}}>
                            {description || `Samsung Ultra represents technological innovation in Samsung's product lineup. Whether it's the latest smartphone model or state-of-the-art home appliance, Samsung Ultra promises an unparalleled experience characterized by exceptional performance and intuitive features.`}
                            </p>
                            <Link to={link  || "/AllTypes"} >
                                <button
                                style={{
                                    padding: "8px",
                                    backgroundColor: "black",
                                    color: "white",
                                    marginTop:"1rem"

                                }}
                                >
                                Shop Now
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HotSale