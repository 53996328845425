import React, { useEffect, useState } from "react";
import "./Footer.css"
import Env from "../Environment/Env";
import { Link } from "react-router-dom";
import Environment from "../Environment/Env";
import axios from "axios";
// import Env from "../Environment/Env";
const Footer = () => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState({})
  useEffect(() => {
    async function fetchingNavbarData() {

      axios.get(`${Env.server}/api/footer`)
        .then((res) => {
          console.log("getting footer response", res)
          setData(res.data)
          setColumns(res.data[0].columns)
        })
        .catch(err => console.log('There is an error in fetching data', err));
    }
    fetchingNavbarData();
  }, [])
  return (
    <>
      <div className="container-fluid text-white footerbackground pr-0 pl-0" style={{ background: "#000" }}>
        {
          data && data.length > 0 ?
            <div className="row pr-5 pl-5">
              <div className="col-lg-3 mb-4">
                <Link to='/'>
                  <img src={data[0].logo} style={{ width: '200px', height: "auto" }} alt='company logo' />
                </Link>
              </div>
              <div className="col-lg-2 ">
                <div >
                  <Link to={columns[0].headingLink}>
                    <h4 className="forhover" style={{ fontSize: "1.3rem" }}>{columns[0].heading}</h4>
                  </Link>
                  {
                    columns[0].subMenu.map((data,index)=>
                    <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>

                      <li className="forhover"><Link to={data.link}>{data.text}</Link></li>
                    </ul>
                    )
                  }
                </div>

              </div>
              <div className="col-lg-3 ">
              <div >
                  <Link to={columns[1].headingLink}>
                    <h4 className="forhover" style={{ fontSize: "1.3rem" }}>{columns[1].heading}</h4>
                  </Link>
                  {
                    columns[1].subMenu.map((data,index)=>
                    <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>

                      <li className="forhover"><Link to={data.link}>{data.text}</Link></li>
                    </ul>
                    )
                  }
                </div>
              </div>
              <div className="col-lg-2 ">
                <div >
                  <Link to={columns[2].headingLink}>
                    <h4 className="forhover" style={{ fontSize: "1.3rem" }}>{columns[2].heading}</h4>
                  </Link>
                  {
                    columns[2].subMenu.map((data,index)=>
                    <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>

                      <li className="forhover"><Link to={data.link}>{data.text}</Link></li>
                    </ul>
                    )
                  }
                </div>

              </div>
              <div className="col-lg-2">
                <h4 style={{ fontSize: "1.4rem" }}>Contact us</h4>
                <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>
                  <li>{data[0].address}</li>
                  <li>{data[0].phoneNum}</li>
                </ul>
                <div className="col-lg-2 ml-5">
                  <div class="social-icons" style={{ color: "yellow" }}>
                    <Link to={data.facebook}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-facebook"></i></div>
                    </Link>
                    <Link to={Env.instagram}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-instagram"></i></div>
                    </Link>
                    <Link to={Env.twitter}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-twitter"></i></div>
                    </Link>
                    <Link to={Env.linkedin}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-linkedin"></i></div>
                    </Link>
                  </div>
                </div>
              </div>
              
            </div>
            :
            <div className="row pr-5 pl-5">
              <div className="col-lg-3 mb-4">
                <Link to='/'>
                  <img src={Env.picLogo} style={{ width: '200px', height: "auto" }} alt='company logo' />
                </Link>
              </div>
              <div className="col-lg-2 ">
                <Link to='/Aboutus'><h4 className="forhover" style={{ fontSize: "1.3rem" }}>About us</h4></Link>
                <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>
                  <Link to='/Cookies'><li className="forhover">GDPR consent</li></Link>
                  <Link to='/Terms'> <li className="forhover">Terms and Conditions</li></Link>
                  <Link to='/Privacy'> <li className="forhover">Privacy Policy</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">FAQs</li> </Link>
                  <Link to='/Terms'> <li className="forhover">Terms and Conditions</li></Link>
                  <Link to='/Privacy'> <li className="forhover">Privacy Policy</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">FAQs</li> </Link>
                </ul>
              </div>
              <div className="col-lg-3 ">
                <Link to='/Aboutus'><h4 className="forhover" style={{ fontSize: "1.3rem" }}>Popular Accessories</h4></Link>
                <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>
                  <Link to='/Cookies'><li className="forhover">Car Phone Holders</li></Link>
                  <Link to='/AmPage4/Cables%20&%20Chargers/Wireless%20Chargers'> <li className="forhover">Wireless Chargers</li></Link>
                  <Link to='/Privacy'> <li className="forhover">Wireless Charging Pads</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">Headphones</li> </Link>
                  <Link to='/Cookies'><li className="forhover">GDPR consent</li></Link>
                  <Link to='/Terms'> <li className="forhover">iPhone Cases</li></Link>
                  <Link to='/Privacy'> <li className="forhover">iPhone Chargers</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">Screen Protectors</li> </Link>
                </ul>
              </div>
              <div className="col-lg-2 ">
                <Link to='/Aboutus'><h4 className="forhover" style={{ fontSize: "1.3rem" }}>Popular Brands</h4></Link>
                <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>
                  <Link to='/Cookies'><li className="forhover">Samsung</li></Link>
                  <Link to='/Terms'> <li className="forhover">Olixar Basics</li></Link>
                  <Link to='/Privacy'> <li className="forhover">Whitestone</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">Spigen</li> </Link>
                  <Link to='/Cookies'><li className="forhover">Apple</li></Link>
                  <Link to='/Terms'> <li className="forhover">Sony</li></Link>
                  <Link to='/Privacy'> <li className="forhover">LoveCases</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">Otterbox</li> </Link>
                  <Link to='/Terms'> <li className="forhover">Nomad</li></Link>
                  <Link to='/Privacy'> <li className="forhover">Araree</li> </Link>
                  <Link to='/Faqs'> <li className="forhover">Zizo</li> </Link>
                </ul>
              </div>

              <div className="col-lg-2">
                <h4 style={{ fontSize: "1.4rem" }}>Contact us</h4>
                <ul style={{ listStyle: 'none', padding: '0', fontSize: "12px" }}>
                  <li>{Env.address}</li>
                  <li>{Env.phone}</li>
                </ul>
                <div className="col-lg-2 ml-5">
                  <div class="social-icons" style={{ color: "yellow" }}>
                    <Link to={Env.facebook}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-facebook"></i></div>
                    </Link>
                    <Link to={Env.instagram}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-instagram"></i></div>
                    </Link>
                    <Link to={Env.twitter}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-twitter"></i></div>
                    </Link>
                    <Link to={Env.linkedin}>
                      <div class="icon" style={{ color: "yellow" }}><i class="bi bi-linkedin"></i></div>
                    </Link>
                  </div>
                </div>
              </div>


            </div>
        }


        {/* <hr /> */}

        <div className="row">
          <div style={{ textAlign: 'center', borderTop: "1px solid yellow" }}>
            <p className="mt-2" style={{ fontSize: "12px" }}>Copyright&#169;2024 All rights Reserved. Powered by WEB ACCESS</p>
          </div>
        </div>

      </div>
    </>
  )
}
export default Footer;