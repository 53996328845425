import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { getAsset } from '../utils/helper';
import "./LandingPage.css";


const ModelSlider = ({ ModelsData, timeInterval = 4000 }) => {
    const navigate = useNavigate();
    const [itemsPerPage,setItemsPerPage] = useState(4);
    // const itemsPerPage = 4;
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSelect = (selectedIndex) => {
        setCurrentSlide(selectedIndex);
    };

    useEffect(()=>{
        const handleResize = ()=>{
            if(window.innerWidth < 992){
                setItemsPerPage(1);
            }else{
                setItemsPerPage(4);
            }
        }
        window.addEventListener('resize',handleResize);
        return ()=>{
            window.removeEventListener('resize',handleResize);
        }
    },[])

    const slides = [];
    for (let i = 0; i < ModelsData.length; i += itemsPerPage) {
        slides.push(ModelsData.slice(i, i + itemsPerPage));
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, timeInterval);
        return () => clearInterval(interval);
    }, [timeInterval, slides.length]);

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",margin:'10px auto' }}>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    {/* <div className="col-1">
                        <button style={{ background: 'none', border: 'none' }} onClick={() => handleSelect((currentSlide - 1 + slides.length) % slides.length)}>
                            <FaChevronLeft size={40} style={{ margin: '10px' }} />
                        </button>
                    </div> */}
                    <div className="col-14" style={{padding:'10px 24px',margin:'0px auto'}} >
                        <Carousel 
                            activeIndex={currentSlide} 
                            onSelect={handleSelect} 
                            indicators={false} 
                            controls={false}
                            interval={null}
                            slide={true}
                        >
                            {slides.map((slide, slideIndex) => (
                                <Carousel.Item key={slideIndex}>
                                    <Container className="product-slider-container">
                                        <Row className="product-slider-row" >
                                            {slide.map((item, index) => (
                                                <div key={index} className="col-lg-6" >
                                                    <div
                                                        className="card text-white"
                                                        style={{ minHeight: "300px",  backgroundColor: '#F8F6ED' }}
                                                    >
                                                        <div style={{display: 'flex', justifyContent: 'center',maxHeight:'70%',objectFit:'cover'}}>
                                                            <img src={getAsset(item.image) } width="70%" height="100%"  style={{}} alt={item.title} />
                                                        </div>
                                                        <div style={{paddingLeft: '15px'}}>
                                                        <h6
                                                            className="card-text"
                                                            style={{
                                                            margin: "0",
                                                            fontWeight: "bold",
                                                            color: "black",
                                                            }}
                                                        >
                                                            {item.title}
                                                        </h6>
                                                        <p
                                                            className="card-text"
                                                            style={{ margin: "0", marginTop: "10px", color: "black", fontSize: '13px' }}
                                                        >
                                                            {item.description || `View model details and its accessories`}
                                                        </p>
                                                        </div>
                                                            <Link to={item.link || '/AllTypes'}>
                                                        <div style={{display: 'flex', justifyContent: 'center'}}>

                                                        <button
                                                            className="shopbutton text-black text-center p-1"
                                                            style={{
                                                            backgroundColor: "black",
                                                            border:"1px solid black",
                                                            width: "50%",
                                                            marginBottom: "15px",
                                                            marginTop: "15px",
                                                            }}
                                                            >
                                                            Shop Now
                                                        </button>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </Row>
                                    </Container>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    {/* <div className="col-1">
                        <button style={{ background: 'none', border: 'none' }} onClick={() => handleSelect((currentSlide + 1) % slides.length)}>
                            <FaChevronRight size={40} style={{ margin: '10px' }} />
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ModelSlider;
